import React, { useState } from "react";
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import { TextField, Select, MenuItem, FormControl, InputLabel, Button } from "@mui/material";
import { DataProviderInstance } from '../../api/DataProvider';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Estilo do editor

function CreateEditTemplateDocument(props) {

  const { SetTemplatesDocuments, UpdateTemplatesDocuments } = DataProviderInstance();

  const { Data, OnHide, OnUpdate } = props;

  const initialTemplateData = {
    id: Data?.id || null,
    companyId: Data?.companyId || "",
    type: Data?.type || "",
    tag: Data?.tag || "",
    title: Data?.title || "",
    text: Data?.text || "",
  };

  const [templateData, setTemplateData] = useState(initialTemplateData);

  const typeOptions = [
    { value: "default", label: "Simples" },
    { value: "receipt", label: "Recibo" },
  ];

  const dynamicTags = [
    { label: "Nome do Paciente", tag: "{patient-name}" },
    { label: "CPF do Paciente", tag: "{patient-cpf}" },
    { label: "Data da Consulta", tag: "{appointment-date}" },
    { label: "Hora da Consulta", tag: "{appointment-time}" },
    { label: "Motivo da Consulta", tag: "{appointment-reason}" },
    { label: "Preço da Consulta", tag: "{appointment-price}" },
    { label: "Hora de Início", tag: "{appointment-startTime}" },
    { label: "Hora de Término", tag: "{appointment-endTime}" },
  ];

  const handleChange = (field, value) => {
    const updatedTemplateData = { ...templateData, [field]: value };
    setTemplateData(updatedTemplateData);
  };

  const addTagToText = (tag) => {
    const updatedText = templateData.text + tag;
    setTemplateData({ ...templateData, text: updatedText });
  };

  const saveClickHandler = () => {
    
    if (Data) {
      UpdateTemplatesDocuments(templateData, () => {
        OnUpdate();
      });
    } else {
      SetTemplatesDocuments(templateData, () => {
        OnUpdate();
      });
    }
  };

  const getPositiveButton = () => (
    <CustomButton
      variant="contained"
      style="primary"
      label={Data ? "Salvar Alterações" : "Adicionar"}
      onClick={saveClickHandler}
    />
  );

  const getContent = () => (
    <div style={{ marginTop: "10px" }}>
      <div className="form">
        <div style={{ display: "flex", gap: "10px" }}>
          {/* Tipo */}
          <FormControl fullWidth style={{ marginBottom: "15px" }}>
            <InputLabel>Tipo</InputLabel>
            <Select
              value={templateData.type}
              onChange={(e) => handleChange("type", e.target.value)}
            >
              {typeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Tag */}
          <TextField
            fullWidth
            label="Tag"
            value={templateData.tag}
            onChange={(e) => handleChange("tag", e.target.value)}
            variant="outlined"
            style={{ marginBottom: "15px" }}
          />
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          {/* Título */}
          <TextField
            fullWidth
            label="Título"
            value={templateData.title}
            onChange={(e) => handleChange("title", e.target.value)}
            variant="outlined"
            style={{ marginBottom: "15px" }}
          />
        </div>

        <div style={{ marginBottom: "15px", display: "flex", flexDirection: "column", gap: "10px" }}>
          <p>Adicionar campo dinâmico:</p>
          <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
            {dynamicTags.map((tag) => (
              <Button
                key={tag.tag}
                variant="outlined"
                size="small"
                onClick={() => addTagToText(tag.tag)}
              >
                {tag.label}
              </Button>
            ))}
          </div>
        </div>

        <div>
           {/* Texto com ReactQuill */}
           <ReactQuill
            value={templateData.text}
            onChange={(value) => handleChange("text", value)}
            theme="snow"
            style={{ height: "300px", marginBottom: "15px" }}
          />
          {/*
          <TextField
            fullWidth
            multiline
            rows={10}
            label="Texto"
            value={templateData.text}
            onChange={(e) => handleChange("text", e.target.value)}
            variant="outlined"
            style={{ marginBottom: "15px" }}
          />
          */}
        </div>
      </div>
    </div>
  );

  const getTitle = () => (Data ? "Editar template de documento" : "Criar novo template de documento");

  function onCloseClickHandler() {
    OnHide();
  }

  return (
    <PopupBase
      Title={getTitle()}
      Content={getContent()}
      Footer={getPositiveButton()}
      OnClose={onCloseClickHandler}
    />
  );
}

export default CreateEditTemplateDocument;
