import React, { useEffect, useState } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import {
  PieChart,
  BarChart,
  BarPlot,
  ChartsXAxis,
  ChartsYAxis,
  ChartsTooltip,
} from '@mui/x-charts';
import ColorPalette from '../../colorpalette/ColorPalette';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import IconNPS from "@mui/icons-material/Analytics";
import IconGoogle from "@mui/icons-material/Google";
import Loading from '../../components/loading/Loading';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DateRangeHeader from '../../components/dateRangeHeader/DateRangeHeader';
import styles from './styles.module.css';

function NPS() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [npsData, setNpsData] = useState({ statistics: null, responses: [] });
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [error, setError] = useState(null);

  const {
    GetNPSStatistics,
    GetNPSResponses,
  } = DataProviderInstance();

  const handleDateChange = (initialDate, finalDate) => {
    setStartDate(initialDate);
    setEndDate(finalDate);
    loadData(initialDate, finalDate);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    loadResponses(event.target.value);
  };

  const loadData = async (start, end) => {
    console.log('Loading data with dates:', { start, end, selectedCategory });
    setIsLoaded(false);
    setError(null);
    
    try {
      const statisticsResult = await GetNPSStatistics(start, end, (result) => {
        console.log('Statistics callback result:', result);
      });
      console.log('Statistics Result:', statisticsResult);

      const responsesResult = await GetNPSResponses(start, end, selectedCategory, (result) => {
        console.log('Responses callback result:', result);
      });
      console.log('Responses Result:', responsesResult);
      
      if (!statisticsResult || !responsesResult) {
        throw new Error('Falha ao carregar dados do NPS');
      }
      
      const newData = {
        statistics: statisticsResult?.status === "Success" ? statisticsResult.data : null,
        responses: responsesResult?.status === "Success" ? responsesResult.data : []
      };
      
      console.log('Setting new NPS data:', newData);
      setNpsData(newData);
      
    } catch (error) {
      console.error('Erro ao carregar dados do NPS:', error);
      setError(error.message);
      setNpsData({
        statistics: null,
        responses: []
      });
    } finally {
      setIsLoaded(true);
    }
  };

  const loadResponses = async (category) => {
    try {
      const responses = await GetNPSResponses(startDate, endDate, category);
      console.log('Responses received:', responses);
      
      setNpsData(prev => {
        const newData = {
          ...prev,
          responses: responses?.status === "Success" ? responses.data : []
        };
        console.log('New NPS data:', newData);
        return newData;
      });
    } catch (error) {
      console.error('Erro ao carregar respostas:', error);
    }
  };

  useEffect(() => {
    console.log('Component mounted, initializing data...');
    
    // Define datas padrão: primeiro dia do mês até hoje
    const end = new Date();
    const start = new Date(end.getFullYear(), end.getMonth(), 1);
    
    console.log('Setting initial dates:', { start, end });
    
    // Formata as datas no formato YYYY-MM-DD
    const formattedStart = start.toISOString().split('T')[0];
    const formattedEnd = end.toISOString().split('T')[0];
    
    setStartDate(formattedStart);
    setEndDate(formattedEnd);
    
    // Reseta o erro antes de carregar
    setError(null);
    
    // Carrega os dados iniciais
    loadData(formattedStart, formattedEnd);
  }, []); // Empty dependency array means this runs once on mount

  function GetNPSOverview() {
    if (!npsData?.statistics) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title="Visão Geral do NPS"
            Description="Métricas principais do Net Promoter Score"
            Icon={<IconNPS />}
          />
          <div>Dados do NPS indisponíveis.</div>
        </div>
      );
    }

    const stats = npsData.statistics;

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title="Visão Geral do NPS"
          Description="Métricas principais do Net Promoter Score"
          Icon={<IconNPS />}
        />
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={3}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.main,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                {stats.nps_score}
              </Typography>
              <Typography variant="subtitle1">
                Score NPS
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.greenLight,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                {stats.promoters}
              </Typography>
              <Typography variant="subtitle1">
                Promotores
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.orange,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                {stats.neutrals}
              </Typography>
              <Typography variant="subtitle1">
                Neutros
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.red,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                {stats.detractors}
              </Typography>
              <Typography variant="subtitle1">
                Detratores
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  function GetGoogleReviewsStatus() {
    if (!npsData?.statistics?.google_reviews) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title="Status das Avaliações Google"
            Description="Acompanhamento das solicitações de avaliação no Google"
            Icon={<IconGoogle />}
          />
          <div>Dados de avaliações Google indisponíveis.</div>
        </div>
      );
    }

    const { google_reviews } = npsData.statistics;

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title="Status das Avaliações Google"
          Description="Acompanhamento das solicitações de avaliação no Google"
          Icon={<IconGoogle />}
        />
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.main,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                {google_reviews.total_requests}
              </Typography>
              <Typography variant="subtitle1">
                Total de Solicitações
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.greenLight,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                {google_reviews.completed_reviews}
              </Typography>
              <Typography variant="subtitle1">
                Avaliações Realizadas
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.mainLight1,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Typography variant="h4" fontWeight="bold">
                {google_reviews.conversion_rate}%
              </Typography>
              <Typography variant="subtitle1">
                Taxa de Conversão
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  function GetResponsesList() {
    console.log('GetResponsesList - Current state:', {
      npsData,
      selectedCategory,
      error
    });

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title="Respostas NPS"
          Description="Lista de todas as respostas recebidas"
          Icon={<IconNPS />}
        />
        
        {/* Seletor de categoria sempre visível */}
        <FormControl fullWidth sx={{ mb: 3, mt: 5 }}>
          <InputLabel>Filtrar por categoria</InputLabel>
          <Select
            value={selectedCategory}
            label="Filtrar por categoria"
            onChange={handleCategoryChange}
          >
            <MenuItem value="all">Todas</MenuItem>
            <MenuItem value="promoter">Promotores</MenuItem>
            <MenuItem value="neutral">Neutros</MenuItem>
            <MenuItem value="detractor">Detratores</MenuItem>
          </Select>
        </FormControl>

        {/* Mensagem de erro */}
        {error && (
          <div style={{color: 'red', marginBottom: '20px'}}>{error}</div>
        )}

        {/* Tabela ou mensagem de sem respostas */}
        {(!Array.isArray(npsData?.responses) || npsData.responses.length === 0) ? (
          <div>Nenhuma resposta encontrada para o período selecionado.</div>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Paciente</strong></TableCell>
                  <TableCell><strong>Data da Consulta</strong></TableCell>
                  <TableCell><strong>Nota</strong></TableCell>
                  <TableCell><strong>Categoria</strong></TableCell>
                  <TableCell><strong>Comentários</strong></TableCell>
                  <TableCell><strong>Status Google</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {npsData.responses.map((response, index) => (
                  <TableRow key={index}>
                    <TableCell>{response.patient_name}</TableCell>
                    <TableCell>{response.appointment_date}</TableCell>
                    <TableCell>{response.score}</TableCell>
                    <TableCell>{response.category}</TableCell>
                    <TableCell>{response.comments || '-'}</TableCell>
                    <TableCell>{response.google_review_status || 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }

  return (
    isLoaded ? (
      <div className='page-content' style={{ gap: "20px", display: "flex", flexDirection: "column" }}>
        <DateRangeHeader
          onChange={handleDateChange}
          initialStartDate={startDate}
          initialEndDate={endDate}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {GetNPSOverview()}
          {GetGoogleReviewsStatus()}
          {GetResponsesList()}
        </div>
      </div>
    ) : (
      <div className='page-content'><Loading /></div>
    )
  );
}

export default NPS;