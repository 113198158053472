import React from "react";
import './RadioItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import ColorPalette from "../../../colorpalette/ColorPalette";

function RadioItem(props)
{
    const {Id, OnClick, Label, Actived}  = props;

    function OnClickHandler()
    {
        OnClick(Id);
    }

    function GetIcon()
    {
        if(Actived)
        {
            return <FontAwesomeIcon icon={faCheckSquare} color={ColorPalette.main} style={{ fontSize: '26px' }} />  
        }
        else
        {
            return <FontAwesomeIcon icon={faSquare} color={ColorPalette.textLight2} style={{ fontSize: '26px' }} />  
        }
    }

    return(
        <div className={(Actived? "radio-item-actived" : "radio-item")} onClick={OnClickHandler}>
            {GetIcon()}{Label}
        </div>
    )
}

export default RadioItem;
