import React, { useEffect, useState, createContext, useContext } from 'react';

import { SetAppointmentInvoiceAPI, SetPatientDocumentAPI, GetPatientDocumentsAPI } from './API';
import { UpdateCompanyAPI, UpdateCompanyConfigAPI, GetCompanyConfigAPI } from './API';
import { GetDashboardAPI } from './API';
import { GetStatisticsAPI } from './API';
import { GetReportsAppointmentsAPI, GetReportsFinancialAPI } from './API';
import { GetPatientsAPI, SetPatientAPI, UpdatePatientAPI } from './API';
import { GetAppointmentsAPI, SetAppointmentAPI, UpdateAppointmentAPI, DeleteAppointmentAPI } from './API';
import { GetCEPInfoAPI } from './API';
import { SetPatientAnamneseAPI, UpdatePatientAnamneseAPI, DeletePatientAnamneseAPI } from './API';
import { SetPatientHistoryAPI } from './API';
import { SetPatientCranialAsymmetryAPI, UpdatePatientCranialAsymmetryAPI, DeletePatientCranialAsymmetryAPI } from './API';
import { GetInvoiceAPI, UpdateInvoiceAPI, UpdateInvoiceNfIssuedAPI } from './API';
import { GetWaitingListAPI, SetWaitingListAPI, UpdateWaitingListAPI, DeleteWaitingListAPI} from './API';
import { GetLeadsAPI, SetLeadAPI, UpdateLeadAPI } from './API';
import { GetTemplatesDocumentsAPI, SetTemplatesDocumentsAPI, UpdateTemplatesDocumentsAPI, DeleteTemplatesDocumentsAPI } from './API';
import { SendWhatsappMessageAPI, GetWhatsappChatsAPI, GetWhatsappChatMessagesAPI } from './API';
import {GetAIAnswerAPI} from './API';
import { GetNPSStatisticsAPI, GetNPSResponsesAPI, SetNPSResponseAPI, UpdateNPSGoogleReviewAPI } from './API';

const DataContext = createContext();

export const DataProvider = ({ children, userData, companyId, OnComplete }) => {

  const [isUpdating, setIsUpdating] = useState(false);
  const [UserData, setUserData] = useState(userData);
  const [CompanyConfig, setCompanyConfig] = useState(null);
  const [patients, SetPatients] = useState([]);
  const [appointments, setAppointments] = useState([]);
  
  useEffect(() => {
    if (companyId === null) {
      return;
    }

    const fetchData = async () => {
      try {
        await Promise.all([GetCompany(), GetPatients()]).then(() => {

          setTimeout(() => {
            OnComplete();
            setIsUpdating(false);
          }, 1000);

        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [companyId]);

  const SetAppointmentInvoice = async (appointmentId, patient, price, paymentMethod, items, issueDocument, callback) => {
    setIsUpdating(true);

    const createdBy = userData.id;

    SetAppointmentInvoiceAPI(appointmentId, companyId, createdBy, patient, price, paymentMethod, items, issueDocument, (response) => {
      GetAppointments();
      callback(response);
    });
  }

  const UpdateInvoice = async (invoiceId, price, paymentMethod, issueDocument, callback) => {
    const updatedBy = userData.id;

    UpdateInvoiceAPI(invoiceId, price, paymentMethod, issueDocument, updatedBy, (response) => {
      callback(response);
    });
  }

  const UpdateInvoiceNfIssued = async (invoiceId, issueDocument, callback) => {
    const updatedBy = userData.id;
    UpdateInvoiceNfIssuedAPI(invoiceId, issueDocument, updatedBy, (response) => {
      callback(response);
    });
  }

  const GetInvoice = async (id, callback) => {
    GetInvoiceAPI(id, (response) => {
      callback(response);
    });
  }

  const UpdateCompanyConfig = async (data, callback) => {
    setIsUpdating(true);
    UpdateCompanyConfigAPI(companyId, data, (result) => {
      GetCompany((result) => {
        setIsUpdating(false);
        callback(result);
      });
    });
  }

  const UpdateCompany = async (data, callback) => {
    
    setIsUpdating(true);

    UpdateCompanyAPI(companyId, data, (result) => {
      GetCompany((result) => {
        setIsUpdating(false);
        callback(result);
      });
    });
  }

  const GetCompany = async (callback) => {

    GetCompanyConfigAPI(companyId, (result) => {
      let data = {};

      data.Id = result.id;
      data.CNPJ = result.cnpj;
      data.Logo = result.logo;

      data.Name = result.name;

      data.Phone = result.phone;
      data.Email = result.email;

      data.Cep = result.cep;
      data.District = result.district;
      data.City = result.city;
      data.State = result.state;
      data.Country = result.country;
      data.Address = result.address;
      data.Number = result.number;
      data.Complement = result.complement;

      data.AvailableDaysOfWeek = JSON.parse(result.availableDaysOfWeek);
      data.AvailableTime = JSON.parse(result.availableTime);
      data.BlockedTimes = JSON.parse(result.blockedTimes);
      
      data.TargetOccupation = JSON.parse(result.targetOccupation);
      data.TimeInterval = JSON.parse(result.timeInterval);
      data.GoalAppointments = JSON.parse(result.goalAppointments);
      data.GoalBilling = JSON.parse(result.goalBilling);
      data.AllowAutomaticWhatsappMessages = result.allowAutomaticWhatsappMessages;

      data.Resources = result.resources;
      data.Procedures = result.procedures;
      data.PaymentOptions = result.paymentOptions;
      data.PatientOrigins = result.patientOrigins;

      data.PatientComplaints = result.patientComplaints;
      data.DocumentTemplates = result.documentTemplates;
      data.AnamneseTemplates = result.anamneseTemplates;

      data.LeadsSources = result.leadsSources;
      data.LeadsCampaigns = result.leadsCampaigns;
      

      setCompanyConfig(data);

      if(callback) callback(data);
    });
  };

  const GetCEPInfo = (cep, callback) => {
    GetCEPInfoAPI(cep, (result) => {
      callback(result);
    });
  }

  const GetPatients = async (dispatchUpdatingEvent = true, callback) => {
    if (dispatchUpdatingEvent) setIsUpdating(true);
    GetPatientsAPI(companyId, (result) => {
      SetPatients(result);
      if (callback) callback(result);
      if (dispatchUpdatingEvent) setIsUpdating(false);
    });
  };

  const GetPatientById = (id) => {
    return patients.find((element) => {
      return element.id == id;
    })
  }

  const SetPatient = async (data, callback) => {

    const createdBy = userData.id;

    SetPatientAPI(companyId, createdBy, data, (response) => {
      if(callback) callback(response);
      GetPatients(false);
    });
  }

  const UpdatePatient = async (data, callback) => {
    UpdatePatientAPI(data, () => {
      GetPatients();
      if(callback) callback();
    });
  }


  const GetAppointments = async (startDate, endDate, callback) => {

    GetAppointmentsAPI(companyId, startDate, endDate, (result) => {

      setAppointments(result);
      
      if(callback)
      {
        callback(result);
      }
    });
  };


  const SetAppointment = async (data, callback) => {

    const createdBy = userData.id;

    SetAppointmentAPI(companyId, createdBy, data, (response) => {

      if (callback !== undefined) {
        callback(response);
      }
    });
  }

  const UpdateAppointment = async (data, callback) => {
    UpdateAppointmentAPI(data, () => {
      if(callback) callback();
    });
  }

  const DeleteAppointment = async (id, callback) => {
    DeleteAppointmentAPI(id, () => {
      if(callback) callback();
    });
  }

  const GetLeads = async (callback) => {
    GetLeadsAPI(companyId, (response) => {
      if(callback) callback(response);
    });
  }

  const SetLead = async (data, callback) => {

    const createdBy = userData.id;
      
   SetLeadAPI(createdBy, data, (response) => {
        if(callback) callback(response);
      });
  }

  const UpdateLead = async (data, callback) => {

    const updatedBy = userData.id;

    UpdateLeadAPI(updatedBy, data, (response) => {
      if(callback) callback(response);
    });

  }

  const GetTemplatesDocuments = async (callback) => {
    GetTemplatesDocumentsAPI(companyId, (response) => {
      if(callback) callback(response);
    });
  }

  const SetTemplatesDocuments = async (data, callback) => {
    const createdBy = userData.id;

    SetTemplatesDocumentsAPI(companyId, createdBy,  data, (response) => {
      if(callback) callback(response);
    });
  }

  const UpdateTemplatesDocuments = async (data, callback) => {

    const updatedBy = userData.id;

    UpdateTemplatesDocumentsAPI(companyId, updatedBy, data, (response) => {
      if(callback) callback(response);
    });
  }

  const DeleteTemplatesDocuments = async (id, callback) => {
    DeleteTemplatesDocumentsAPI(id, (response) => {
      if(callback) callback(response);
    });
  }

  const GetDashboard = async (data) => {
    GetDashboardAPI(companyId, data, () => {
    });
  }

  const GetStatistics = async (startDate, endDate, callback) => {
    GetStatisticsAPI(companyId, startDate, endDate, callback);
  }

  const GetReportsAppointments = async (startDate, endDate, callback) => {
    GetReportsAppointmentsAPI(companyId, startDate, endDate,callback);
  }

  const GetReportsFinancial = async (startDate, endDate, callback) => {
    GetReportsFinancialAPI(companyId, startDate, endDate, callback);
  }

  function GetProcedureById(id) {
    return CompanyConfig.Procedures.find(p => p.Id == id);
  }

  function GetResourceById(id) {
    return CompanyConfig.Resources.find(p => p.Id == id);
  }

  const SetPatientAnamnese = async (currentDate, patientId, appointmentId, json, template, callback) => {
    const createdBy = userData.id;

    SetPatientAnamneseAPI(currentDate, createdBy, patientId, appointmentId, json, template, (response) => {
      callback(response);
    });
  };

  const UpdatePatientAnamnese = async (id, patientId, json, callback) => {
    const updatedBy = userData.id;

    UpdatePatientAnamneseAPI(id, patientId, json, updatedBy, (response) => {
      callback(response);
    });
  };

  const DeletePatientAnamnese = async (id, callback) => {
    const deletedBy = userData.id;

    DeletePatientAnamneseAPI(id, deletedBy, (response) => {
      callback(response);
    });
  };

  const SetPatientHistory = async (currentDate, patientId, appointmentId, assessment, evolution, callback) => {

    const createdBy = userData.id;

    SetPatientHistoryAPI(currentDate, createdBy, patientId, appointmentId, assessment, evolution, (response) => {
      callback(response);
    });
  };

  const SetPatientCranialAsymmetry = async (currentDate, patientId, appointmentId, ab, bp, dd, de, callback) => {
    const createdBy = userData.id;

    SetPatientCranialAsymmetryAPI(currentDate, createdBy, patientId, appointmentId, ab, bp, dd, de, (response) => {
      callback(response);
    });
  }

  const UpdatePatientCranialAsymmetry = async (id, currentDate, patientId, appointmentId, ap, bp, dd, de, callback) => {
    UpdatePatientCranialAsymmetryAPI(id, currentDate, patientId, appointmentId, ap, bp, dd, de, (response) => {
      callback(response);
    });
  }

  const DeletePatientCranialAsymmetry = async (id, callback) => {
    DeletePatientCranialAsymmetryAPI(id, (response) => {
      callback(response);
    });
  }

  const SetPatientDocument = async (patientId, date, appointmentId, title, description, callback) => {

    const createdBy = userData.id;

    SetPatientDocumentAPI(patientId, date, appointmentId, title, description, createdBy, (response) => {
      callback(response);
    });
  };

  const GetPatientDocuments = async (patientId, callback) => {
    GetPatientDocumentsAPI(patientId, (response) => {
      callback(response);
    });
  };

  const GetWaitingList = async (callback) => {
    GetWaitingListAPI(companyId, (result) => {
      callback(result);
    });
  }

  const SetWaitingList = async (data, callback) => {
    const createdBy = userData.id;

    SetWaitingListAPI(companyId, createdBy, data, (result) => {
      callback(result);
    });
  }

  const UpdateWaitingList = async (data, callback) => {
    const updatedBy = userData.id;

    UpdateWaitingListAPI(updatedBy, data , (result) => {
      callback(result);
    });
  }

  const DeleteWaitingList = async (id, callback) => {
    DeleteWaitingListAPI(id, (result) => {
      callback(result);
    });
  }

  const SendWhatsappMessage = async (to, text, delay, callback) => {
    SendWhatsappMessageAPI(to, text, delay, (result) => {
      if(callback) callback(result);
    });
  }

  const GetWhatsappChats = async (callback) => {
    GetWhatsappChatsAPI(companyId, (result) => {
      if(callback) callback(result);
    });
  }
 
  const GetWhatsappChatMessages = async (chatId, callback) => {
    GetWhatsappChatMessagesAPI(companyId, chatId, (result) => {
      if(callback)  callback(result);
    });
  }

  const GetAIAnswer = async (questions, callback) => {
    
      GetAIAnswerAPI(companyId, questions, (result) => {
        if(callback) callback(result);
      });
  }

  const GetNPSStatistics = async (startDate, endDate, callback) => {
    return new Promise((resolve) => {
      GetNPSStatisticsAPI(companyId, startDate, endDate, (result) => {
        console.log('NPS Statistics API Result:', result);
        if(callback) callback(result);
        resolve(result);
      });
    });
  }

  const GetNPSResponses = async (startDate, endDate, category, callback) => {
    return new Promise((resolve) => {
      GetNPSResponsesAPI(companyId, startDate, endDate, category, (result) => {
        console.log('NPS Responses API Result:', result);
        if(callback) callback(result);
        resolve(result);
      });
    });
  }

  const SetNPSResponse = async (patientId, appointmentId, score, comments, callback) => {
    SetNPSResponseAPI(companyId, patientId, appointmentId, score, comments, (result) => {
      if(callback) callback(result);
    });
  }

  const UpdateNPSGoogleReview = async (reviewId, status, callback) => {
    UpdateNPSGoogleReviewAPI(companyId, reviewId, status, (result) => {
      if(callback) callback(result);
    });
  }

  const allData =
  {

    isUpdating,
    UserData,
    CompanyConfig, UpdateCompany,UpdateCompanyConfig,
    GetLeads, SetLead , UpdateLead,
    GetProcedureById, GetResourceById,
    GetCEPInfo,
    GetDashboard,
    GetStatistics,
    GetReportsAppointments,GetReportsFinancial,
    patients, GetPatients, SetPatient, UpdatePatient, GetPatientById,
    SetPatientAnamnese, UpdatePatientAnamnese, DeletePatientAnamnese, SetPatientDocument, GetPatientDocuments,
    appointments,GetAppointments, SetAppointment, UpdateAppointment, DeleteAppointment,
    GetWaitingList, SetWaitingList,UpdateWaitingList, DeleteWaitingList,
    GetInvoice, SetAppointmentInvoice,UpdateInvoice,UpdateInvoiceNfIssued,
    SetPatientHistory, SetPatientCranialAsymmetry, UpdatePatientCranialAsymmetry, DeletePatientCranialAsymmetry,
    GetTemplatesDocuments, SetTemplatesDocuments, UpdateTemplatesDocuments,DeleteTemplatesDocuments,
    GetWhatsappChats,GetWhatsappChatMessages, SendWhatsappMessage,
    GetAIAnswer,
    // NPS functions
    GetNPSStatistics, GetNPSResponses, SetNPSResponse, UpdateNPSGoogleReview
  };

  return (
    <DataContext.Provider value={allData}>
      {children}
    </DataContext.Provider>
  );
};

export const DataProviderInstance = () => useContext(DataContext);