import React, { useRef, useState, useEffect } from "react";
import PopupBase from "../base/PopupBase";
import { DataProviderInstance } from '../../api/DataProvider';
import CustomButton from "../../components/buttons/CustomButton";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import styles from './CreateAnamnesePopupContent.module.css';
import DynamicForm from "../../components/dynamicForm/DynamicForm";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function CreateAnamnesePopupContent(props) {
    const { CompanyConfig, SetPatientAnamnese, UpdatePatientAnamnese, DeletePatientAnamnese } = DataProviderInstance();

    const { currentDate, patientId, OnSave, OnHide, anamnese } = props;
    const dynamicFormRef = useRef(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showMainPopup, setShowMainPopup] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState(() => {
        if (anamnese) {

            const template = CompanyConfig.AnamneseTemplates.find(template => {
                return template.Id == anamnese.template;
            });


            if (!template) {
                return CompanyConfig.AnamneseTemplates[0];
            }
            
            return template;
        } else {
            return CompanyConfig.AnamneseTemplates[0];
        }
    });

    useEffect(() => {

            if (anamnese) {
                const answers = JSON.parse(anamnese.anamnese);
                dynamicFormRef.current.SetValue(answers);
            } else {
                dynamicFormRef.current.Reset();
            }
    }, []);

    function OnCloseClickHandler() {
        Hide();
    }

    function OnCancelClickHandler() {
        Hide();
    }

    function OnConfirmClickHandler() {
        if (dynamicFormRef.current.Validate()) {
            SaveData();
            //Hide();
        }
    }

    function OnDeleteClickHandler() {
        setShowMainPopup(false);
        setShowConfirmDelete(true);
    }

    function OnCancelDelete() {
        setShowConfirmDelete(false);
        Hide();
    }

    function ExecuteDelete() {
        if (anamnese) {
            DeletePatientAnamnese(anamnese.id, (response) => {
                setShowConfirmDelete(false);
                OnSave();
                Hide();
            });
        }
    }

    function Hide() {
        OnHide();
    }

    function SaveData() {
        const answers = dynamicFormRef.current.GetValue();
        const json = answers;

        if (anamnese) {
            UpdatePatientAnamnese(anamnese.id, patientId, json, (response) => {
                OnSave();
                Hide();
            });
        } else {
            const appointmentId = 0; // not usign appointmentId for now
            SetPatientAnamnese(currentDate, patientId, appointmentId, json, selectedTemplate.Id, (response) => {
                OnSave();
                Hide();
            });
        }
    }

    function GetTitle() {
        return anamnese ? "Editar anamnese" : "Criar nova anamnese";
    }

    function handleTemplateChange(event) {
        const selectedValue = event.target.value;
        const selectedTemplate = CompanyConfig.AnamneseTemplates.find(
            (template) => template.Label === selectedValue
        );
        setSelectedTemplate(selectedTemplate);
    }

    function GetContent() {
        return (
            <div>
                {!anamnese && (
                    <div className={styles.templatesContainer}>
                        Moldelos:
                        <FormControl>
                            <Select
                                labelId="template-select-label"
                                id="template-select"
                                defaultValue={selectedTemplate.Label}
                                onChange={handleTemplateChange}
                            >
                                {CompanyConfig.AnamneseTemplates.map((template) => (
                                    <MenuItem key={template.Label} value={template.Label}>
                                        {template.Label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}
                <DynamicForm
                    key={selectedTemplate ? selectedTemplate.label : "default"}
                    ref={dynamicFormRef}
                    questions={selectedTemplate ? JSON.parse(selectedTemplate.Questions) : []}
                />
            </div>
        );
    }

    function GetFooter() {
        return (
            <div style={{display:"flex", gap:"10px", justifyContent: anamnese ? "space-between" : "flex-end"}}>
                {anamnese && (
                    <CustomButton
                        variant="contained"
                        style="error"
                        label="Apagar anamnese"
                        onClick={OnDeleteClickHandler}
                    />
                )}
                <div style={{display:"flex", gap:"10px"}}>
                    <CustomButton
                        variant="outlined"
                        style="secondary"
                        label={"Cancelar"}
                        onClick={OnCancelClickHandler}
                    />

                    <CustomButton
                        style="primary"
                        variant="contained"
                        label={anamnese ? "Salvar alterações" : "Registrar"}
                        onClick={OnConfirmClickHandler}
                    />
                </div>
            </div>
        );
    }

    function GetRender() {
        return (
            <>
                {showMainPopup && (
                    <PopupBase
                        Title={GetTitle()}
                        Content={GetContent()}
                        Footer={GetFooter()}
                        OnClose={OnCloseClickHandler}
                    />
                )}
                <ConfirmPopup
                    Visibility={showConfirmDelete}
                    Title="Confirmar exclusão"
                    Description="Tem certeza que deseja apagar esta anamnese? Esta ação não pode ser desfeita."
                    ConfirmLabel="Apagar"
                    OnConfirm={ExecuteDelete}
                    OnCancel={OnCancelDelete}
                    OnHide={OnCancelDelete}
                />
            </>
        );
    }

    return GetRender();
}

export default CreateAnamnesePopupContent;