import React, { useEffect, useState, useRef } from "react";
import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PsychologyIcon from '@mui/icons-material/Psychology';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Loading from "../../components/loading/Loading";
import { DataProviderInstance } from "../../api/DataProvider";
import ColorPalette from "../../colorpalette/ColorPalette";
import styles from "./WhatsappMessages.module.css";

const formatPhoneNumber = (phone) => {
  const number = phone.replace(/^55/, '');
  return `(${number.slice(0,2)}) ${number.slice(2,7)}-${number.slice(7)}`;
};

const FILTER_OPTIONS = {
  ALL: "Todos",
  UNREAD: "Não lidas",
};

function WhatsappMessages() {
  const { GetWhatsappChats, GetWhatsappChatMessages, SendWhatsappMessage, GetAIAnswer } = DataProviderInstance();

  const [chats, setChats] = useState([]);
  const [filteredChats, setFilteredChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [filterOption, setFilterOption] = useState(FILTER_OPTIONS.ALL);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loadingChats, setLoadingChats] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [showChatList, setShowChatList] = useState(true);

  const [lastSeenMessages, setLastSeenMessages] = useState(() => {
    const savedLastSeenMessages = localStorage.getItem("lastSeenMessages");
    return savedLastSeenMessages ? JSON.parse(savedLastSeenMessages) : {};
  });

  const messagesContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobileView(mobile);
      if (!mobile) {
        setShowChatList(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let filtered = chats;

    if (filterOption === FILTER_OPTIONS.UNREAD) {
      filtered = filtered.filter((chat) => {
        const phone1 = chat.senderPhone < chat.receiverPhone ? chat.senderPhone : chat.receiverPhone;
        const phone2 = chat.senderPhone < chat.receiverPhone ? chat.receiverPhone : chat.senderPhone;
        const chatKey = `${phone1}-${phone2}`;
        const lastSeenId = lastSeenMessages[chatKey] || null;
        const isUnread = chat.direction?.toLowerCase() === "received" && lastSeenId !== chat.messageId;
        return isUnread;
      });
    }

    setFilteredChats(filtered);
  }, [filterOption, chats, lastSeenMessages]);

  useEffect(() => {
    const fetchChats = () => {
      GetWhatsappChats((result) => {
        setChats(result.data);
        setFilteredChats(result.data);
        setLoadingChats(false);
      });
    };

    fetchChats();
    const intervalId = setInterval(fetchChats, 30000);
    return () => clearInterval(intervalId);
  }, [GetWhatsappChats]);

  useEffect(() => {
    if (selectedChat) {
      setLoadingMessages(true);
      if (isMobileView) {
        setShowChatList(false);
      }

      GetWhatsappChatMessages(selectedChat.senderPhone, (result) => {
        setMessages(result.data);
        setLoadingMessages(false);

        setLastSeenMessages((prev) => {
          const phone1 = selectedChat.senderPhone < selectedChat.receiverPhone ? selectedChat.senderPhone : selectedChat.receiverPhone;
          const phone2 = selectedChat.senderPhone < selectedChat.receiverPhone ? selectedChat.receiverPhone : selectedChat.senderPhone;
          const chatKey = `${phone1}-${phone2}`;
          const updatedLastSeenMessages = {
            ...prev,
            [chatKey]: result.data.filter(msg => msg.direction.toLowerCase() === "received").slice(-1)[0]?.messageId || null,
          };
          localStorage.setItem("lastSeenMessages", JSON.stringify(updatedLastSeenMessages));
          return updatedLastSeenMessages;
        });

        setTimeout(() => {
          if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
          }
        }, 100);
      });
    }
  }, [selectedChat, GetWhatsappChatMessages, isMobileView]);

  const filterChats = (searchText) => {
    let filtered = chats;
    if (searchText) {
      filtered = filtered.filter((chat) =>
        chat.chatName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setFilteredChats(filtered);
  };

  const handleIATip = () => {
    if (messages) {
      let allMessages = messages;
      const messageTexts = allMessages.slice(-1).map(msg => msg.messageText);
      GetAIAnswer(messageTexts, (result) => {
        if(result.error === false) {
          setNewMessage(result.message);
        }
      });
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;

    const receivedMessage = messages.find(
      (msg) => msg.direction === "received"
    );

    const messageData = {
      companyId: selectedChat.companyId,
      chatId: selectedChat.chatId,
      messageText: newMessage,
      direction: "sent",
      senderPhone: receivedMessage ? receivedMessage.senderPhone : "",
    };

    SendWhatsappMessage(messageData.senderPhone, messageData.messageText, 0);

    setMessages((prev) => [
      ...prev,
      { ...messageData, date: new Date().toISOString() },
    ]);

    setNewMessage("");

    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  const handleBackToList = () => {
    setShowChatList(true);
  };

  return (
    <div className="page-content">
      <div className={styles.container}>
        <div className={`${styles.chatList} ${!showChatList ? styles.chatListHidden : ''}`}>
          <div className={styles.header}>
            <h3>Chats</h3>
            <ButtonGroup variant="outlined" fullWidth style={{ marginBottom: "10px" }}>
              <Button
                onClick={() => setFilterOption(FILTER_OPTIONS.ALL)}
                style={{
                  flex: 1,
                  backgroundColor: filterOption === FILTER_OPTIONS.ALL ? ColorPalette.mainLight1 : 'transparent',
                  color: filterOption === FILTER_OPTIONS.ALL ? ColorPalette.main : 'inherit',
                  borderColor: filterOption === FILTER_OPTIONS.ALL ? ColorPalette.main : 'rgba(0, 0, 0, 0.23)',
                  textTransform: 'none',
                  fontWeight: filterOption === FILTER_OPTIONS.ALL ? 600 : 400
                }}
              >
                {FILTER_OPTIONS.ALL}
              </Button>
              <Button
                onClick={() => setFilterOption(FILTER_OPTIONS.UNREAD)}
                style={{
                  flex: 1,
                  backgroundColor: filterOption === FILTER_OPTIONS.UNREAD ? ColorPalette.mainLight1 : 'transparent',
                  color: filterOption === FILTER_OPTIONS.UNREAD ? ColorPalette.main : 'inherit',
                  borderColor: filterOption === FILTER_OPTIONS.UNREAD ? ColorPalette.main : 'rgba(0, 0, 0, 0.23)',
                  textTransform: 'none',
                  fontWeight: filterOption === FILTER_OPTIONS.UNREAD ? 600 : 400
                }}
              >
                {FILTER_OPTIONS.UNREAD}
              </Button>
            </ButtonGroup>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Pesquisar chats..."
              onChange={(e) => filterChats(e.target.value)}
              fullWidth
            />
          </div>
          <Divider />
          {loadingChats ? (
            <Loading />
          ) : (
            <List style={{ flex: 1, overflowY: "auto" }}>
              {filteredChats.map((chat) => {
                const chatDate = new Date(chat.createdAt);
                const isToday = chatDate.toDateString() === new Date().toDateString();
                const phone1 = chat.senderPhone < chat.receiverPhone ? chat.senderPhone : chat.receiverPhone;
                const phone2 = chat.senderPhone < chat.receiverPhone ? chat.receiverPhone : chat.senderPhone;
                const chatKey = `${phone1}-${phone2}`;
                const lastSeenId = lastSeenMessages[chatKey] || null;
                const isUnread = chat.direction?.toLowerCase() === "received" && lastSeenId !== chat.messageId;

                return (
                  <ListItem
                    key={`${chat.senderPhone < chat.receiverPhone ? chat.senderPhone : chat.receiverPhone}-${chat.senderPhone < chat.receiverPhone ? chat.receiverPhone : chat.senderPhone}`}
                    onClick={() => setSelectedChat(chat)}
                    selected={
                      (selectedChat?.senderPhone === chat.senderPhone && selectedChat?.receiverPhone === chat.receiverPhone) ||
                      (selectedChat?.senderPhone === chat.receiverPhone && selectedChat?.receiverPhone === chat.senderPhone)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <ListItemAvatar>
                      <Avatar src={chat.chatPhoto} alt={chat.chatName} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={chat.chatName}
                      secondary={chat.messageText.substring(0, 25) + "..."}
                    />
                    <Typography variant="caption" color="textSecondary">
                      {isToday
                        ? chatDate.toLocaleTimeString()
                        : chatDate.toLocaleDateString()}
                    </Typography>
                    {isUnread && <Badge color="primary" variant="dot" />}
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>

        <div className={`${styles.chatArea} ${showChatList && isMobileView ? styles.chatAreaHidden : ''}`}>
          <div style={{
            borderBottom: "1px solid #ddd",
            padding: "16px",
            backgroundColor: "#f9f9f9",
            display: "flex",
            alignItems: "center",
            gap: "16px"
          }}>
            {isMobileView && (
              <IconButton className={styles.backButton} onClick={handleBackToList}>
                <ArrowBackIcon />
              </IconButton>
            )}
            {selectedChat ? (
              <>
                <Avatar
                  src={selectedChat.chatPhoto}
                  alt={selectedChat.chatName}
                  sx={{ width: 48, height: 48 }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                  <Typography variant="h6" style={{ margin: 0, fontSize: '1.1rem', fontWeight: 500 }}>
                    {selectedChat.chatName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.9rem' }}>
                    {formatPhoneNumber(selectedChat.senderPhone)}
                  </Typography>
                </div>
              </>
            ) : (
              <Typography variant="h6">Selecione um chat</Typography>
            )}
          </div>

          <div ref={messagesContainerRef} className={styles.messageContainer}>
            {loadingMessages ? (
              <Loading />
            ) : (
              messages.map((msg, index) => {
                let parsedMsg = msg.messageText.split("\\n");
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: msg.direction === "sent" ? "flex-end" : "flex-start",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "8px",
                        backgroundColor: msg.direction === "sent" ? ColorPalette.mainLight1 : ColorPalette.secondaryLight,
                        maxWidth: "350px",
                        wordWrap: "break-word",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        {parsedMsg.map((line, i) => (
                          <p key={i}>{line}</p>
                        ))}
                      </div>
                      <Typography variant="caption" color="textSecondary">
                        {new Date(msg.date).toLocaleTimeString()}
                      </Typography>
                    </div>
                  </div>
                );
              })
            )}
          </div>

          {selectedChat && (
            <div className={styles.inputArea}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Digite sua mensagem..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<SendIcon />}
                onClick={handleSendMessage}
              >
                Enviar
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PsychologyIcon />}
                onClick={handleIATip}
              >
                IA Responde
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WhatsappMessages;
