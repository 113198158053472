import React, { useEffect, useState } from 'react';
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import IconSave from '@mui/icons-material/SaveAltOutlined';
import IconDelete from '@mui/icons-material/Delete';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import { DataProviderInstance } from "../../api/DataProvider";
import InputField from "../../components/inputfield/InputField";
import { LocalizationProvider, TimeField, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";


function CreateCranialAsymmetryEntryPopup(props) {

    const { patientId, appointmentId, OnHide, entry } = props;
    const { CompanyConfig, SetPatientCranialAsymmetry, UpdatePatientCranialAsymmetry, DeletePatientCranialAsymmetry } = DataProviderInstance();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const formatDate = (date) => {
        return dayjs(date).set('hour', dayjs().hour()).set('minute', dayjs().minute()).set('second', dayjs().second()).format('YYYY-MM-DD HH:mm:ss');
    }

    const [date, setDate] = useState(entry ? formatDate(dayjs(entry.createdAt)) : formatDate(dayjs()));
    const [apValue, setAPValue] = useState(entry ? entry.ap : "");
    const [bpValue, setBPValue] = useState(entry ? entry.bp : "");
    const [ddValue, setDDValue] = useState(entry ? entry.dd : "");
    const [deValue, setDEValue] = useState(entry ? entry.de : "");

    const inputAPref = React.createRef(null);
    const inputBPRef = React.createRef(null);
    const inputDDRef = React.createRef(null);
    const inputDERef = React.createRef(null);

    function OnCloseClickHandler() {
        Hide(false);
    }

    function Hide(needsPageReload) {
        OnHide(needsPageReload);
    }

    function saveHandler() {
        if (Validate()) {
            if (entry) {
                // Update
                UpdatePatientCranialAsymmetry(
                    entry.id,
                    date,
                    patientId,
                    appointmentId,
                    apValue,
                    bpValue,
                    ddValue,
                    deValue,
                    (response) => {
                        if (response.status === "SUCCESS") {
                            Hide(true);
                        }
                    }
                );
            } else {
                // Create new
                SetPatientCranialAsymmetry(
                    date,
                    patientId,
                    appointmentId,
                    apValue,
                    bpValue,
                    ddValue,
                    deValue,
                    (response) => {
                        if (response.status === "SUCCESS") {
                            Hide(true);
                        }
                    }
                );
            }
        }
    }

    function deleteHandler() {
        DeletePatientCranialAsymmetry(entry.id, (response) => {
            if (response.status === "SUCCESS") {
                Hide(true);
            }
        });
    }

    function Validate() {
        return inputAPref.current.Validate() && inputBPRef.current.Validate() && inputDDRef.current.Validate() && inputDERef.current.Validate() && date != null;
    }

    function GetFooter() {
        return (
            <div style={{ display: "flex", gap: "10px" }}>
                {entry && (
                    <CustomButton
                        variant="contained"
                        style="error"
                        label={"Excluir"}
                        icon={<IconDelete />}
                        onClick={() => deleteHandler()}
                    />
                )}

                <CustomButton
                    variant="outlined"
                    style="secondary"
                    label={"Cancelar"}
                    onClick={() => Hide(false)}
                />

                <CustomButton
                    style="primary"
                    variant="contained"
                    label={entry ? "Atualizar" : "Registrar"}
                    onClick={saveHandler}
                />

                {showConfirmDelete && (
                    <ConfirmPopup
                        Title="Confirmar exclusão"
                        Message="Tem certeza que deseja excluir esta medida?"
                        OnConfirm={deleteHandler}
                        OnCancel={() => setShowConfirmDelete(false)}
                    />
                )}
            </div>
        );
    }

  
    const handleDateChange = (newValue) => {
        if (newValue != null) {
            const formattedDate = formatDate(newValue)
            setDate(formattedDate);
        }
        else {
            setDate(null);
        }
    }

    function GetContent() {

        return (
            <div style={{ display: "flex", flexDirection: "column", gap: "50px", flexWrap: "wrap", marginTop: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker
                        label="Data da medição"
                        defaultValue={dayjs()}
                        format='DD/MM/YYYY'
                        disableFuture={true}
                        onChange={(newValue) => handleDateChange(newValue)}
                    />
                </LocalizationProvider>

                <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
                    <InputField type={"number"} Mandatory={true} Value={apValue} Label={"AP"} ref={inputAPref} OnUpdateValue={(value) => setAPValue(value)} />
                    <InputField type={"number"} Mandatory={true} Value={bpValue} Label={"BP"} ref={inputBPRef} OnUpdateValue={(value) => setBPValue(value)} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap" }}>
                    <InputField type={"number"} Mandatory={true} Value={ddValue} Label={"DD"} ref={inputDDRef} OnUpdateValue={(value) => setDDValue(value)} />
                    <InputField type={"number"} Mandatory={true} Value={deValue} Label={"DE"} ref={inputDERef} OnUpdateValue={(value) => setDEValue(value)} />
                </div>
            </div>
        )
    }

    return <PopupBase Title={entry ? "Editar medidas" : "Adicionar medidas"} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />
}

export default CreateCranialAsymmetryEntryPopup;