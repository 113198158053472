import React, { useState } from 'react';
import style from './AnamneseInfo.module.css';
import CalendarConfig from '../../../../../consts/CalendarConfig';
import CustomButton from '../../../../../components/buttons/CustomButton';
const AnamneseInfo = ({ data, onEdit }) => {
    console.log('AnamneseInfo props:', { data, onEdit });
    const list = JSON.parse(data.anamnese);

    return (
        <div className={style.container}>
            {onEdit &&
            <div className={style.editButton}>
            <CustomButton label={'Editar'}
            onClick={onEdit}
            variant={'outlined'}
            />
        </div>
            }
            
            <div className={style.list}>
                {/*<p className={style.date}>{CalendarConfig.GetDDMMAAAA(data.createdAt)}</p>*/}
                {list.map((item, index) => (
                    <div key={index} className={style.item}>
                        <h5 className={style.question}> {item.label}</h5>
                        <p className={style.answer}> {item.value}</p>
                        {item.observation && <span className={style.obs}><b>Obs:</b> {item.observation}</span>}
                    </div>
                ))}
            </div>
        </div>
    )
};

export default AnamneseInfo;