import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import ColorPalette from '../../../../colorpalette/ColorPalette';
import { LineChart, ChartsXAxis, ChartsYAxis, ChartsTooltip, ChartsLegend, LinePlot } from '@mui/x-charts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CustomButton from '../../../../components/buttons/CustomButton';
import dayjs from 'dayjs';
import IconAdd from '@mui/icons-material/Add';
import IconEdit from '@mui/icons-material/Edit';
import CreateCranialAsymmetryEntryPopup from '../../../../popup/CreateCranialAsymmetryEntryPopup/CreateCranialAsymmetryEntryPopup';

const CranialAsymmetry = ({ appointmentId, patientId, data, onUpdated, enabled}) => {

    const [history, setHistory] = React.useState(data ? data : []);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null);

    useEffect(() => {
        setHistory(data);
    }, [data]);

    const getParam1 = (entry) => {

        const ap = entry.ap;
        const bp = entry.bp;
        const normalizedDifference = bp / ap;
        const percentage = (normalizedDifference * 100).toFixed(2);

        const scale = [
            { min: 0, max: 65, severity: "Muito grave", color: "darkred", textColor: "white" },
            { min: 65.01, max: 68, severity: "Grave", color: "red", textColor: "white" },
            { min: 68.01, max: 71, severity: "Moderada", color: "orange", textColor: "white" },
            { min: 71.01, max: 75, severity: "Leve", color: "yellow", textColor: "black" },
            { min: 75.01, max: 90, severity: "Normal", color: "green", textColor: "white" },
            { min: 90.01, max: 94, severity: "Leve", color: "yellow", textColor: "black" },
            { min: 94.01, max: 98, severity: "Moderada", color: "orange", textColor: "white" },
            { min: 98.01, max: 102, severity: "Severa", color: "red", textColor: "white" },
            { min: 102.01, max: 9999999.99, severity: "Muito severa", color: "darkred", textColor: "white" },
        ];

        const severity = scale.find(item => percentage >= item.min && percentage <= item.max);

        if (!severity) {
            return [percentage, { severity: "Desconhecido", color: "gray", textColor: "black" }];
        }

        return [percentage, severity];
    };

    const getPlagiocephalySeverityScale = (entry) => {

        const dd = entry.dd;
        const de = entry.de;

        const digMaior = dd > de ? dd : de;
        const digMenor = dd < de ? dd : de;

        const normalizedDifference = (digMaior - digMenor) / digMaior;
        const percentage = (normalizedDifference * 100).toFixed(2);

        const scale = [
            { min: 0, max: 3.4, severity: "Normal", color: "green", textColor: "white" },
            { min: 3.5, max: 6.25, severity: "Leve", color: "yellow", textColor: "black" },
            { min: 6.26, max: 8.75, severity: "Moderada", color: "orange", textColor: "white" },
            { min: 8.76, max: 10.99, severity: "Severa", color: "red", textColor: "white" },
            { min: 11.00, max: 9999999.99, severity: "Muito severa", color: "darkred", textColor: "white" },
        ];

        const severity = scale.find(item => percentage >= item.min && percentage <= item.max);

        if (!severity) {
            return [percentage, { severity: "Desconhecido", color: "gray", textColor: "black" }];
        }

        return [percentage, severity];
    };

    const getChip = (value, label, color, textColor) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '3px' }}>
                <span>{`${value}`}</span>
                <div style={{ borderRadius: '20px', paddingBlock: '2px', paddingInline: '10px', backgroundColor: color }}>
                    <span style={{ fontSize: '12px', color: textColor, whiteSpace: 'nowrap' }}>{`${label}`}</span>
                </div>
            </div>
        );
    }

    const formatDate = (date) => dayjs(date).format('DD/MM/YYYY');

    const getGraph = () => {
        const xLabels = history.map(entry => formatDate(entry.createdAt));
        const param1Data = history.map(entry => getParam1(entry)[0]);
        const plagiocephalySeverityScale = history.map(entry => getPlagiocephalySeverityScale(entry)[0]);

        return (
            <div className={styles.containerChart}>
                <LineChart
                    series={[
                        { data: param1Data, label: 'param1' },
                        { data: plagiocephalySeverityScale, label: 'Escala Plagiocefalia' },
                    ]}
                    xAxis={[{ scaleType: 'band', data: xLabels }]}
                    colors={[ColorPalette.main, ColorPalette.secondary]}
                    width={550}
                    height={400}
                >
                    <ChartsXAxis />
                    <ChartsYAxis />
                    <ChartsTooltip />
                    <ChartsLegend />
                    <LinePlot
                        data={param1Data}
                        name="Param1 (AP - BP)"
                        color="#206afa"
                    />
                    <LinePlot
                        data={plagiocephalySeverityScale}
                        name="Escala Plagiocefalia"
                        color="#ff7f50"
                    />
                </LineChart>
            </div>
        );
    }

    function popupHideHandler(needsReload) {
        setShowPopup(false);

        if (needsReload) {
            onUpdated();
        }
    }


    function addItemHandler() {
        setSelectedEntry(null);
        setShowPopup(true);
    }

    return (
        <div className={styles.container}>
            <div className={styles.containerHeader}>
                <AreaTitle Title={"Assimetria Craniana"} Description={history.length === 0 ? "Não existem registros de medidas." : ""} />
                {enabled && <CustomButton
                    variant="outlined"
                    style="primary"
                    label={""}
                    icon={<IconAdd />}
                    onClick={() => addItemHandler()}
                    enabled={enabled}
                />}
            </div>
            <div className={styles.tableContainer}>
                {history.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table aria-label="cranial asymmetry table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Data</strong></TableCell>
                                    <TableCell align="right"><strong>AP</strong></TableCell>
                                    <TableCell align="right"><strong>BP</strong></TableCell>
                                    <TableCell align="right"><strong>Escala AP/BP</strong></TableCell>
                                    <TableCell align="right"><strong>DD</strong></TableCell>
                                    <TableCell align="right"><strong>DE</strong></TableCell>
                                    <TableCell align="right"><strong>Plagiocefalia</strong></TableCell>
                                    <TableCell align="right"><strong>Ações</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {history.map((item, index) => {
                                    const [plagioPercentage, plagioSeverity] = getPlagiocephalySeverityScale(item);
                                    const [otherPercentage, otherSeverity] = getParam1(item);
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(item.createdAt)}</TableCell>
                                            <TableCell align="right">{item.ap}</TableCell>
                                            <TableCell align="right">{item.bp}</TableCell>
                                            <TableCell align="right">
                                                {getChip(otherPercentage + "%", otherSeverity.severity, otherSeverity.color, otherSeverity.textColor)}
                                            </TableCell>
                                            <TableCell align="right">{item.dd}</TableCell>
                                            <TableCell align="right">{item.de}</TableCell>
                                            <TableCell align="right">
                                                {getChip(plagioPercentage, plagioSeverity.severity, plagioSeverity.color, plagioSeverity.textColor)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {enabled && (
                                                    <CustomButton
                                                        variant="outlined"
                                                        style="primary"
                                                        label={""}
                                                        icon={<IconEdit />}
                                                        onClick={() => {
                                                            setSelectedEntry(item);
                                                            setShowPopup(true);
                                                        }}
                                                        enabled={enabled}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <div>Nenhum dado disponível para a tabela.</div>
                )}
            </div>
            {showPopup && <CreateCranialAsymmetryEntryPopup appointmentId={appointmentId} patientId={patientId} OnHide={popupHideHandler} entry={selectedEntry} />}
        </div>
    );

};

export default CranialAsymmetry;
