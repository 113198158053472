export const Channel_Whatsapp = "whatsapp";
export const Channel_Facebook = "facebook";
export const Channel_Instagram = "instagram";
export const Channel_Phone = "phone";
export const Channel_Email = "email";
export const Channel_Other = "other";

export const Status_New = "new"
export const Status_InProgress = "in_progress";
export const Status_WaitingResponse = "waiting_response";
export const Status_Finished = "finished";

export const Result_Pending = "pending";
export const Result_Success = "success";
export const Result_Failure = "failure";
export const Result_Failure_Inactive = "failure_inactive";

const LeadsConfig = {

    StatusNew : Status_New,
    StatusInProgress : Status_InProgress,
    StatusWaitingResponse : Status_WaitingResponse,
    StatusFinished : Status_Finished,

    ResultPending : Result_Pending,
    ResultSuccess : Result_Success,
    ResultFailure : Result_Failure,
    ResultFailureInactive : Result_Failure_Inactive,

    ChannelOptions: [
        Channel_Whatsapp,
        Channel_Facebook,
        Channel_Instagram,
        Channel_Phone,
        Channel_Email,
        Channel_Other
    ],
    
    StatusOptions: [
        Status_New,
        Status_InProgress,
        Status_WaitingResponse,
        Status_Finished
    ],

    ResultOptions: [
        Result_Pending,
        Result_Success,
        Result_Failure,
        Result_Failure_Inactive
    ]
}

export default LeadsConfig;
