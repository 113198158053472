import React, { useEffect } from 'react';
import { useState } from "react";
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import CalendarConfig from '../../consts/CalendarConfig';
import MessagesTemplate from "../../consts/MessagesTemplate";
import IconBlocked from '@mui/icons-material/BlockOutlined';
import IconAppointment from '@mui/icons-material/CalendarTodayRounded';
import { LocalizationProvider, TimeField, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import AppointmensState from "../../consts/AppointmentsState";
import { AppointmentModel } from '../../consts/Models';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DataProviderInstance } from '../../api/DataProvider';
import Alert from '@mui/material/Alert';
import { CalendarMonth, AccessTime } from '@mui/icons-material';
import { Paper } from '@mui/material';


function AvailableDatePopupContent(props) {

    const { CreateBlockCallback, CreateAppointmentCallback, OnHide } = props;
    let { Data } = props;

    const [currentDate, setCurrentDate] = useState(GetInitialDate());
    const [currentTime, setCurrentTime] = useState(GetInitialTime());
    const [hasValidDate, setHasValidDate] = useState(false);
    const [busySchedule, setBusySchedule] = useState(false);

    const {
        appointments,
    } = DataProviderInstance();

    useEffect(() => {
        checkValidDate();
    }, [currentDate, currentTime]);

    function OnCloseClickHandler() {
        Hide();
    }

    function Hide() {
        OnHide();
    }

    function OnSuggestDate() {

      //  SaveDate();

        let message = MessagesTemplate.SuggestDate;

        let date = `${currentDate} ${currentTime}:00`;
        let yyyymmdd = CalendarConfig.GetAAAAMMDDArray(date);

        let monthDay = yyyymmdd[2];
        let monthName = CalendarConfig.Months[yyyymmdd[1] - 1];

        // Get the index of the day of the week (0 for Sunday, 1 for Monday, etc.)
        let dayOfWeekIndex = dayjs(date).day();
       
        message = message.replaceAll("{day}", monthDay);
        message = message.replaceAll("{month}", monthName);
        message = message.replaceAll("{time}", CalendarConfig.GetTime(date));
        message = message.replaceAll("{when}", CalendarConfig.DaysOfWeek[dayOfWeekIndex]);

        navigator.clipboard.writeText(MessagesTemplate.ConvertToClipboardText(message));

        alert('Sugestão de data copiada');
        OnCloseClickHandler();
    }

    function SaveDate() {
        // '2024-02-02 10:00:00'
        const value = `${currentDate} ${currentTime}:00`;

        if (Data == null) {
            Data = new AppointmentModel();
            Data.id = null;
            Data.status = AppointmensState.Available;
            Data.date = value;
        } else {
            Data.date = value;
        }

        return Data;
    }

    function OnCreateBlockHandler() {
        let data = SaveDate();
        CreateBlockCallback(data);
    }

    function OnCreateAppointmentHandler() {
        let data = SaveDate();
        CreateAppointmentCallback(data);
    }

    function handleDateChange(value) {
        if (value !== null && !isNaN(value.$D)) {
            const formattedDate = value.$y + "-" + (value.$M + 1) + "-" + value.$D;
            setCurrentDate(formattedDate);
        } else {
            setCurrentDate(null);
        }
    }

    function checkAvailableDate(date, time) 
    {
        const dateToCheckDateTime = new Date(date + ' ' + time);
        let result = true;

        appointments.forEach(appointment => {
            let appointmentDateTime = new Date(appointment.date);

            if (AppointmensState.NonBlockers.includes(appointment.status)) {
                return;
            }

            if (appointmentDateTime.getTime() === dateToCheckDateTime.getTime()) {
                result = false;
            }
        });

        setBusySchedule(!result);
    }

    function handleTimeChange(value) {
        if (value !== null && !isNaN(value.$D)) {
            // Força apenas horas cheias
            const hour = dayjs(value.$d).hour();
            const formattedTime = `${hour.toString().padStart(2, '0')}:00`;
            setCurrentTime(formattedTime);
          
        } else {
            setCurrentTime(null);
        }
    }

    function checkValidDate() {
        if (currentDate != null && currentTime != null) {
            setHasValidDate(true);
            checkAvailableDate(currentDate, currentTime);
        } else {
            setHasValidDate(false);
        }
    }

    function GetInitialDate() {
        if (Data == null || Data.date == null) {
            return null;
        }

        const formattedDate = Data.date.split(' ')[0];

        return formattedDate;
    }

    function GetInitialTime() {
        if (Data == null || Data.date == null) {
            return null;
        }

        const date = Data.date;
        const time = date.split(' ')[1];
        return time;
    }


    function GetFooter() {
        return (
            <div style={{ display: "flex", justifyContent: "flex-start", width: "100%", alignItems: "center", gap: "10px" }}>
                <CustomButton variant="outlined" style="tertiary" label={"Sugerir data"} onClick={OnSuggestDate} enabled={hasValidDate} />
                <CustomButton variant="outlined" style="secondary" label={"Bloqueio"} onClick={OnCreateBlockHandler} enabled={hasValidDate && !busySchedule} />
                <CustomButton variant="contained" style="primary" label={"Agendamento"} onClick={OnCreateAppointmentHandler} enabled={hasValidDate} />
            </div>
        )
    }

    function GetContent() {
        let hasDate = Data && Data.date;

        return (
            <Paper elevation={0} sx={{ p: 3, border: '1px solid #e0e0e0', borderRadius: 2 }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "30px", flexWrap: "wrap" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px", flex: 1, minWidth: "250px" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <CalendarMonth color="primary" />
                                <h5 style={{ margin: 0 }}>Data</h5>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    defaultValue={hasDate ? dayjs(Data.date, 'YYYY-MM-DD') : null}
                                    format='DD/MM/YYYY'
                                    onChange={(newValue) => handleDateChange(newValue)}
                                    disabled={hasDate}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px", flex: 1, minWidth: "250px" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <AccessTime color="primary" />
                                <h5 style={{ margin: 0 }}>Horário</h5>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    format="HH:00"
                                    defaultValue={hasDate ? dayjs(GetInitialTime(), 'HH:mm') : null}
                                    onChange={(newValue) => handleTimeChange(newValue)}
                                    disabled={hasDate}
                                    views={['hours']}
                                    ampm={false}
                                    skipDisabled={true}
                                    minTime={dayjs().set('hour', 8).startOf('hour')}
                                    maxTime={dayjs().set('hour', 18).startOf('hour')}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                    }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    {busySchedule && (
                        <Alert
                            severity="warning"
                            sx={{
                                '& .MuiAlert-icon': {
                                    fontSize: '24px'
                                },
                                borderRadius: 2
                            }}
                        >
                            Já existe um agendamento nesse horário
                        </Alert>
                    )}
                    {currentTime && !busySchedule && (
                        <Alert
                            severity="info"
                            sx={{
                                '& .MuiAlert-icon': {
                                    fontSize: '24px'
                                },
                                borderRadius: 2
                            }}
                        >
                            Horário disponível para agendamento
                        </Alert>
                    )}
                    <h4 style={{ margin: "10px 0 0 0" }}>O que gostaria de fazer?</h4>
                </div>
            </Paper>
        );
    }

    return <PopupBase Title={"Novo compromisso"} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />;
}

export default AvailableDatePopupContent;
