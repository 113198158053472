import React, { useEffect, useState, useRef } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import CustomButton from '../../components/buttons/CustomButton';
import styles from './Leads.module.css';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import IconEdit from '@mui/icons-material/Edit';
import CreateEditLeadPopup from '../../popup/CreateEditLeadPopup/CreateEditLeadPopup';
import DateRangeHeader from '../../components/dateRangeHeader/DateRangeHeader';
import FilterList from '../../components/filters/FilterList';
import { ExportToExcel } from '../../utils/Utils';
import { MenuItem, Select, Switch, FormControlLabel } from '@mui/material';
import { CheckOutlined, CloseRounded } from '@mui/icons-material';
import ColorPalette from '../../colorpalette/ColorPalette';
import LeadsConfig from '../../consts/LeadsConfig';
import LeadCardItem from './LeadCardItem';
import { SendWhatsAppMessage } from "../../api/WhatsappAPI";
import MessagesTemplate from "../../consts/MessagesTemplate";

function Leads() {

  const
    {
      CompanyConfig,
      GetLeads,
    } = DataProviderInstance()


  const maxItemsPerPage = 25000000;
  const [loaded, setLoaded] = useState(false);
  const [leads, setLeads] = useState([]);
  const [filtered, setFiltered] = React.useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentLead, setCurrentLead] = useState(null);
  const [createOrEditPopupVisibility, setCreateOrEditPopupVisibility] = useState(false);

  const [currentFilters, setCurrentFilters] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [needsReload, setNeedsReload] = React.useState(false);

  const filters = ["Faturadas", "Faturadas e pediram nota fiscal"];
  const filterRef = useRef(null);

  const [viewInvalid, setViewInvalid] = useState(false); 

  useEffect(() => {
    setNeedsReload(true);
  }, []);

  useEffect(() => {
    if (needsReload) {
      setLoaded(false);
      LoadData();
    }
  }, [needsReload]);

  const LoadData = () => {
    GetLeads((result) => {
      setLeads(result.data);
      setLoaded(true);
      setNeedsReload(false);
    });
  }

  useEffect(() => {
    const parseDate = (dateString) => {
      if (!dateString) return null;

      // Divida a string no formato "YYYY-MM-DD"
      const [year, month, day] = dateString.split("-").map(Number);

      // Crie a data localmente
      return new Date(year, month - 1, day); // `month - 1` porque o mês começa em 0
    };

    const startDateParsed = parseDate(startDate);
    if (startDateParsed) {
      startDateParsed.setHours(0, 0, 0, 0);
    }

    const endDateParsed = parseDate(endDate);
    if (endDateParsed) {
      endDateParsed.setHours(23, 59, 59, 999);
    }

    const filtered = leads.filter((lead) => {
      const leadDate = new Date(lead.createdAt);

      return (
        (!startDateParsed || leadDate >= startDateParsed) &&
        (!endDateParsed || leadDate <= endDateParsed)
      );
    });

    setFiltered(filtered);
  }, [startDate, endDate, leads]);


  const filterChangeHandler = (filters) => {
    setCurrentFilters(filters);
  }

  const getColumn = () => {
    const columns = {
      new: [],
      inProgress: [],
      waitingResponse:[],
      finished: [],
      invalid: [],
    };

    filtered.forEach((leadData, i) => {
      const {
        createdAt,
        valid,
        name,
        phone,
        channel,
        result,
        campaignId,
        observations,
        status,
      } = leadData;

      var leadElement = <LeadCardItem key={i} leadData={leadData} onEdit={()=>showCreateEditLeadPopup(leadData)} onUpdate={()=>setNeedsReload(true)} onWhatssappMessageClick={()=>onWhatssappMessageClickHandler(leadData)} />;

      if (valid == "0") {
        columns.invalid.push(leadElement);
      } else {
        if (status == LeadsConfig.StatusNew) {
          columns.new.push(leadElement);
        } else if (status == LeadsConfig.StatusInProgress) {
          columns.inProgress.push(leadElement);
        } 
        else if (status == LeadsConfig.StatusWaitingResponse) {
          columns.waitingResponse.push(leadElement);
        } else if (status == LeadsConfig.StatusFinished) {
            columns.finished.push(leadElement);
          }
        }
    });

    return (
      <div className={styles.containerColumns}>
        <div className={styles.column}>
          <h3>{`Novo (${columns.new.length})`}</h3>
          {columns.new}
        </div>
        <div className={styles.column}>
          <h3>{`Em andamento (${columns.inProgress.length})`}</h3>
          {columns.inProgress}
        </div>
        <div className={styles.column}>
          <h3>{`Aguardando retorno (${columns.waitingResponse.length})`}</h3>
          {columns.waitingResponse}
        </div>
        <div className={styles.column}>
          <h3>{`Finalizado (${columns.finished.length})`}</h3>
          {columns.finished}
        </div>
        {viewInvalid && (<div className={styles.column}>
          <h3>{`Inválido (${columns.invalid.length})`}</h3>
          {columns.invalid}
        </div>)}
      </div>
    );
  };

  function onWhatssappMessageClickHandler(leadData) {
    let message = "";
    SendWhatsAppMessage(leadData.phone, MessagesTemplate.ConvertToWhatsAppText(message));
  }
 
  function showCreateEditLeadPopup(leadData) {
    setCurrentLead(leadData);
    setCreateOrEditPopupVisibility(true);
  }

  function onCreateEditLeadPopupHide() {
    setCurrentLead(null);
    setCreateOrEditPopupVisibility(false);
  }

  function onLeadUpdate() {
    setNeedsReload(true);
  }

  function onCreateLeadClickHandler() {
    setCurrentLead(null);
    setCreateOrEditPopupVisibility(true);

  }

  function onExportExcelClickHandler() {
    let data = [...filtered];

    data = data.map(({ id, companyId, phone, name, sourceId, campaignId, updatedAt, updatedBy
      , ...rest }) => {
      let campaign = CompanyConfig.LeadsCampaigns.find(x => x.id == campaignId + "");
      let campaignName = campaign.name;
      let source = CompanyConfig.LeadsSources.find(x => x.id == campaign.sourceId + "")?.name;
      return { ...rest, source, campaignName };
    });

    ExportToExcel(data, "Leads", "leads-" + startDate + "-" + endDate);
  }

  const handleDateChange = (initialDate, endDate) => {
    setStartDate(initialDate);
    setEndDate(endDate);
    //loadData(initialDate, endDate);
  }

  function formatDate(date) {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  return (
    <div className='page-content'>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "20px" }}>
          <AreaTitle Title='Leads' Description={"Contatos que mostraram interesse nos serviços da clínica."} />
          {<CustomButton
            variant="contained"
            style="primary"
            label={"Adicionar"}
            onClick={onCreateLeadClickHandler}
          />}
        </div>

        <DateRangeHeader onChange={handleDateChange} />

        <div style={{ display: "flex", flex: "1", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <AreaTitle Title="Resultado" Description={`<b>${filtered.length} leads</b> encontrados no período de <b>${formatDate(startDate)} à ${formatDate(endDate)}</b>`} />
        </div>
      
        {getColumn()}

        {createOrEditPopupVisibility && <CreateEditLeadPopup OnHide={onCreateEditLeadPopupHide} OnUpdate={onLeadUpdate} Data={currentLead} />}
      </div>
    </div>
  );
}

export default Leads;