import React, { useState } from 'react';
import styles from './MainPain.module.css';
import CustomButton from "../../../../components/buttons/CustomButton";
import IconAdd from '@mui/icons-material/Add';
import MainPainItem from './MainPainItem';
import CircleIcon from '../../../../components/circleIcon/CircleIcon';
import Icon from "@mui/icons-material/WarningAmberRounded";
import ComplaintsPopupContent from '../../../../popup/ComplaintsPopupContent/ComplaintsPopupContent';

const MainPain = ({ onChange, list, history, enabled }) => {

  const [pains, setPains] = useState(list);
  const [popupVisible, setPopupVisible] = useState(false);

  function OnAddHandler() {
    setPopupVisible(true);
  }

  function OnComplaintAddItemsHandler(values) {
    let newValues = values.map((item) => item.label);
    SaveValues([...pains, ...newValues]);
  }

  function OnRemoveItemHandler(item) {
    if (!enabled) return;
    let newItems = pains.filter((i) => i != item);
    SaveValues(newItems);
  }

  function SaveValues(values) {
    setPains(values);
    onChange(values);
  }

  function cleanHtmlTags(text) {
    return text.replace(/<[^>]*>?/gm, '');
  }

  const assessment = history && history[history.length - 1] ? cleanHtmlTags(history[history.length - 1].assessment) : '';

  return (
    <div className={styles.container}>
      {popupVisible && <ComplaintsPopupContent InitialData={pains} OnSave={OnComplaintAddItemsHandler} OnHide={() => setPopupVisible(false)} />}
      <div className={styles.containerTitle}>
        <div style={{ display: "flex", width:"100%", alignContent:"space-between", flexDirection: "column", gap: "4px" }}>
          <h3>Queixas principais</h3>
          {pains.length == 0 ? <p>Não existem queixas registradas.</p>
            :<p>Não existem queixas registradas.</p>}
        </div>
        <CustomButton
          variant="outlined"
          style="primary"
          label={""}
          icon={<IconAdd />}
          onClick={OnAddHandler}
          fullWidth={false}
          enabled={enabled}
        />
      </div>
      <div className={styles.painList}>
        {pains.map((item, index) => (
          <MainPainItem key={index} label={item} enabled={enabled} OnRemove={OnRemoveItemHandler} />
        ))}
      </div>
      {history && history[history.length - 1] && (
        <div style={{ display: "flex", flexDirection: "column", gap: "4px", backgroundColor: "#f5f5f5", padding: "20px", borderRadius: "8px" }}>
          <b>Primeira avaliação: </b>
          <span>{assessment}</span>
        </div>
      )}
    </div>
  );
};

export default MainPain;
