import React, { useEffect } from 'react';
import { useState } from "react";
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import CalendarConfig from '../../consts/CalendarConfig';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import AppointmensState from "../../consts/AppointmentsState";
import { AppointmentModel } from '../../consts/Models';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DataProviderInstance } from '../../api/DataProvider';
import Alert from '@mui/material/Alert';
import { CalendarMonth, AccessTime } from '@mui/icons-material';
import { Paper, Switch } from '@mui/material';

function ReschedulePopupContent(props) {
    const { OnConfirm, OnHide } = props;

      const { CompanyConfig, GetAppointments } = DataProviderInstance();

    const keySendWhatssappReminder = "sendAppointmentCreationWhatssappReminder";
    const [sendWhatssappReminder, setSendWhatssappReminder] = useState(localStorage.getItem(keySendWhatssappReminder) === "true");
    const [currentDate, setCurrentDate] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);
    const [hasValidDate, setHasValidDate] = useState(false);
    const [busySchedule, setBusySchedule] = useState(false);

    const {
        appointments,
    } = DataProviderInstance();

    useEffect(() => {
        localStorage.setItem(keySendWhatssappReminder, sendWhatssappReminder);
    }, [sendWhatssappReminder]);

    useEffect(() => {
        checkValidDate();
    }, [currentDate, currentTime]);

    function OnCloseClickHandler() {
        // Apenas fecha o popup sem fazer nenhuma alteração
        OnHide();
    }

    function OnConfirmClickHandler() {
        if (!currentDate || !currentTime || busySchedule) {
            return;
        }
        
        const value = `${currentDate} ${currentTime}:00`;
        let data = new AppointmentModel();
        data.id = null;
        data.date = value;
        data.sendWhatsappReminder = sendWhatssappReminder;
        
        OnConfirm(data);
    }

    function handleDateChange(value) {
        if (value !== null && !isNaN(value.$D)) {
            const formattedDate = value.$y + "-" + (value.$M + 1) + "-" + value.$D;
            setCurrentDate(formattedDate);
        } else {
            setCurrentDate(null);
        }
    }

    function checkAvailableDate(date, time) {
        const dateToCheckDateTime = new Date(date + ' ' + time);
        let result = true;

        appointments.forEach(appointment => {
            let appointmentDateTime = new Date(appointment.date);

            if (AppointmensState.NonBlockers.includes(appointment.status)) {
                return;
            }

            if (appointmentDateTime.getTime() === dateToCheckDateTime.getTime()) {
                result = false;
            }
        });

        setBusySchedule(!result);
    }

    function handleTimeChange(value) {
        if (value !== null && !isNaN(value.$D)) {
            const hour = dayjs(value.$d).hour();
            const formattedTime = `${hour.toString().padStart(2, '0')}:00`;
            setCurrentTime(formattedTime);
        } else {
            setCurrentTime(null);
        }
    }

    function checkValidDate() {
        if (currentDate != null && currentTime != null) {
            setHasValidDate(true);
            checkAvailableDate(currentDate, currentTime);
        } else {
            setHasValidDate(false);
        }
    }

    function GetFooter() {
        return (
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "center", gap: "10px" }}>
                <CustomButton variant="outlined" style="secondary" label={"Cancelar"} onClick={OnCloseClickHandler} />
                <CustomButton variant="contained" style="primary" label={"Confirmar"} onClick={OnConfirmClickHandler} enabled={hasValidDate && !busySchedule} />
            </div>
        )
    }

    function GetContent() {
        return (
            <Paper elevation={0} sx={{ p: 3, border: '1px solid #e0e0e0', borderRadius: 2 }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "30px", flexWrap: "wrap" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px", flex: 1, minWidth: "250px" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <CalendarMonth color="primary" />
                                <h5 style={{ margin: 0 }}>Data</h5>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    onChange={(newValue) => handleDateChange(newValue)}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px", flex: 1, minWidth: "250px" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <AccessTime color="primary" />
                                <h5 style={{ margin: 0 }}>Horário</h5>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    format="HH:00"
                                    onChange={(newValue) => handleTimeChange(newValue)}
                                    views={['hours']}
                                    ampm={false}
                                    skipDisabled={true}
                                    minTime={dayjs().set('hour', CompanyConfig.AvailableTime[0]).startOf('hour')}
                                    maxTime={dayjs().set('hour', CompanyConfig.AvailableTime[1]).startOf('hour')}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                    }}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    {busySchedule && (
                        <Alert
                            severity="warning"
                            sx={{
                                '& .MuiAlert-icon': {
                                    fontSize: '24px'
                                },
                                borderRadius: 2
                            }}
                        >
                            Já existe um agendamento nesse horário
                        </Alert>
                    )}
                    {currentTime && !busySchedule && (
                        <Alert
                            severity="info"
                            sx={{
                                '& .MuiAlert-icon': {
                                    fontSize: '24px'
                                },
                                borderRadius: 2
                            }}
                        >
                            Horário disponível para agendamento
                        </Alert>
                    )}
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <p style={{ margin: 0 }}>Enviar lembrete por WhatsApp?</p>
                        <Switch
                            checked={sendWhatssappReminder}
                            onChange={(event) => setSendWhatssappReminder(event.target.checked)}
                        />
                    </div>
                </div>
            </Paper>
        );
    }

    return <PopupBase Title={"Reagendar compromisso"} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />;
}

export default ReschedulePopupContent;