import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './NPSForm.css';
import RemotePath from '../../consts/RemotePaths';

function NPSForm() {
  const { token: routeToken } = useParams();
  const [searchParams] = useState(new URLSearchParams(window.location.search));
  const token = routeToken || searchParams.get('i');
  
  const [selectedScore, setSelectedScore] = useState(null);
  const [comments, setComments] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [appointmentInfo, setAppointmentInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPromoter, setIsPromoter] = useState(false);
  const [pathLogo, setPathLogo] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [scoreConfirmed, setScoreConfirmed] = useState(false);

  useEffect(() => {
    const fetchAppointmentInfo = async () => {
      try {
        if (!token) {
          setError('Link inválido. Por favor, verifique a URL.');
          setIsLoading(false);
          return;
        }

        const response = await fetch(
          `https://www.maisqueagenda.app.br/api/get_nps_appointment_info.php?i=${encodeURIComponent(token)}`,
          {
            headers: {
              'Accept': 'application/json'
            }
          }
        );

        if (!response.ok) {
          if (response.status === 429) {
            throw new Error('Muitas requisições. Por favor, tente novamente mais tarde.');
          }
          throw new Error('Erro ao carregar informações');
        }

        const data = await response.json();

        if (data.status === 'success') {
          setAppointmentInfo(data.data);
          if (data.data.company_logo) {
            setPathLogo(RemotePath.PATH_USER_UPLOADS_COMPANY_LOGO(data.data.companyId) + data.data.company_logo);
          }
        } else {
          throw new Error(data.message || 'Erro ao carregar informações');
        }
      } catch (err) {
        setError(err.message || 'Não foi possível carregar as informações do agendamento.');
        console.error('Erro ao carregar informações:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAppointmentInfo();
  }, [token]);

  const getScoreCategory = (score) => {
    if (score >= 9) return 'promoter';
    if (score >= 7) return 'neutral';
    return 'detractor';
  };

  const saveToAPI = async (score, comment = '') => {
    const formData = new FormData();
    formData.append('i', token);
    formData.append('score', score);
    formData.append('comments', comment.trim());

    try {
      const response = await fetch('https://www.maisqueagenda.app.br/api/set_nps_response.php', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        if (response.status === 429) {
          throw new Error('Muitas requisições. Por favor, tente novamente mais tarde.');
        }
        throw new Error('Erro ao enviar avaliação');
      }

      const data = await response.json();
      if (data?.status !== "Success") {
        throw new Error(data?.message || 'Falha ao enviar avaliação');
      }
    } catch (err) {
      console.error('Erro ao salvar NPS:', err);
    }
  };

  const handleScoreSelect = (score) => {
    setSelectedScore(score);
    setIsPromoter(score >= 9);
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (selectedScore === null) {
      setError('Por favor, selecione uma nota antes de enviar.');
      return;
    }

    if (!token) {
      setError('Link inválido. Por favor, verifique a URL.');
      return;
    }

    if (!scoreConfirmed) {
      // Salva a resposta quando confirma a nota
      await saveToAPI(selectedScore, comments);
      setScoreConfirmed(true);
      setShowComments(!isPromoter);
      return;
    }

    // Se for promoter, não faz nada após confirmar a nota
    if (isPromoter) {
      return;
    }

    setIsSubmitting(true);
    setError(null);

    const formData = new FormData();
    formData.append('i', token);
    formData.append('score', selectedScore);
    formData.append('comments', comments.trim());

    try {
      const response = await fetch('https://www.maisqueagenda.app.br/api/set_nps_response.php', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        if (response.status === 429) {
          throw new Error('Muitas requisições. Por favor, tente novamente mais tarde.');
        }
        throw new Error('Erro ao enviar avaliação');
      }

      const data = await response.json();

      if (data?.status === "Success") {
        setIsSubmitted(true);
      } else {
        throw new Error(data?.message || 'Falha ao enviar avaliação');
      }
    } catch (err) {
      setError(err.message || 'Ocorreu um erro ao enviar sua avaliação. Por favor, tente novamente.');
      console.error('Erro ao enviar NPS:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleReview = async () => {
    if (!token || !appointmentInfo?.google_review_url) {
      console.error('Token inválido ou URL do Google Review não configurada');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      // Primeiro envia a avaliação
      const formData = new FormData();
      formData.append('i', token);
      formData.append('score', selectedScore);
      formData.append('comments', '');

      const responseNPS = await fetch('https://www.maisqueagenda.app.br/api/set_nps_response.php', {
        method: 'POST',
        body: formData
      });

      if (!responseNPS.ok) {
        throw new Error('Erro ao enviar avaliação');
      }

      const dataNPS = await responseNPS.json();
      if (dataNPS?.status !== "Success") {
        throw new Error(dataNPS?.message || 'Falha ao enviar avaliação');
      }

      // Depois atualiza o status do review
      const responseReview = await fetch('https://www.maisqueagenda.app.br/api/update_nps_google_review.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          'i': token,
          'status': 'completed'
        })
      });

      if (!responseReview.ok) {
        throw new Error('Erro ao atualizar status do review');
      }

      setIsSubmitted(true);
      window.open(appointmentInfo.google_review_url, '_blank');
    } catch (err) {
      setError(err.message || 'Ocorreu um erro ao processar sua avaliação.');
      console.error('Erro ao processar review:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="nps-form-container">
        <div className="loading-message">
          <p>Carregando informações...</p>
        </div>
      </div>
    );
  }

  if (error && !appointmentInfo) {
    return (
      <div className="nps-form-container">
        <div className="error-message">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <div className="nps-form-container">
        <div className="header-container">
          {appointmentInfo?.company_logo && (
            <div className="company-logo">
              <img src={pathLogo} alt={appointmentInfo.company_name} />
            </div>
          )}
        </div>
        <div className="thank-you-message">
          <h2>Muito Obrigada!</h2>
          {isPromoter && appointmentInfo?.google_review_url ? (
            <>
              <p>Por compartilhar sua experiência!<br/><br/>Sua opinião é extremamente importante para nós<br/>e nos motiva a continuar oferecendo o melhor cuidado possível.<br/><br/>Saber que podemos contar com seu apoio significa muito<br/>para toda a nossa equipe! </p>
              <button
                onClick={handleGoogleReview}
                className="google-review-button"
                aria-label="Ver avaliação no Google"
              >
                <span>Ver avaliação no Google</span>
              </button>
            </>
          ) : (
            <p>Agradecemos pela confiança!</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="nps-form-container">
      <div className="header-container">
        {appointmentInfo?.company_logo && (
          <div className="company-logo">
            <img src={pathLogo} alt={appointmentInfo.company_name} />
          </div>
        )}
        {(!scoreConfirmed || !isPromoter) && (
          <div className="header-info">
            <h2>Pesquisa de satisfação</h2>
            <p className="nps-description">
              Em uma escala de 0 a 10, qual a probabilidade de você recomendar<br/>nosso trabalho para amigos e familiares?
            </p>
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        {!scoreConfirmed && (
          <>
            <div className="nps-score-selector">
              {[...Array(11)].map((_, index) => (
                <button
                  key={index}
                  type="button"
                  className={`score-button ${
                    selectedScore === index ? 'selected' : ''
                  } ${selectedScore === index ? getScoreCategory(index) : ''}`}
                  onClick={() => handleScoreSelect(index)}
                >
                  {index}
                </button>
              ))}
            </div>

            <div className="score-labels">
              <span>Pouco provável</span>
              <span>Muito provável</span>
            </div>
          </>
        )}

        {scoreConfirmed && !isPromoter && (
          <div className="score-confirmation">
            <h3>Nota {selectedScore}</h3>
            <p className={`score-status ${getScoreCategory(selectedScore)}`}>
              {selectedScore >= 7 ? (
                'Agradecemos sua avaliação e gostaríamos de saber como podemos melhorar ainda mais.'
              ) : (
                'Lamentamos não ter atendido suas expectativas.'
              )}
            </p>
            <p className="feedback-title">Sua opinião é muito importante!</p>
            <p className="feedback-request">
              Por favor, conte-nos mais sobre sua experiência para que possamos melhorar nossos serviços.
            </p>
          </div>
        )}

        {scoreConfirmed && (
          <>
            {isPromoter ? (
              <div className="thank-you-message">
                <h2>Obrigada!</h2>
                <p>Ficamos muito felizes em saber que você<br />teve uma ótima experiência! ❤️<br /><br /><b>Deixar sua opinião no Google</b> ajudará mais pessoas a conhecerem nosso trabalho e receberem o cuidado que elas precisam.<br /><br /><b>Podemos contar com sua avaliação?</b><br />Basta clicar no botão abaixo.🙏</p>
                <button
                  onClick={handleGoogleReview}
                  className="google-review-button"
                  type="button"
                  aria-label="Escrever avaliação no Google"
                >
                  <span>Escrever avaliação no Google</span>
                </button>
              </div>
            ) : (
              <>
                <div className="comments-section">
                  <div className="comments-input-container">
                    <textarea
                      id="comments"
                      className="comments-input"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      placeholder="Descreva aqui sua experiência..."
                    />
                  </div>
                </div>

                {error && <div className="error-message">{error}</div>}

                <button
                  type="submit"
                  className="submit-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Enviando...' : 'Enviar Avaliação'}
                </button>
              </>
            )}
          </>
        )}

        {!scoreConfirmed && (
          <>
            {error && <div className="error-message">{error}</div>}
            <button
              type="submit"
              className="submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Enviando...' : 'Confirmar Nota'}
            </button>
          </>
        )}
      </form>
    </div>
  );
}

export default NPSForm;