import React, { useEffect, useState, useRef } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import styles from './Reports.module.css';
import TabBar from '../../components/tab-bar/TabBar';
import Leads from './areas/leads/Leads';
import Appointments from './areas/appointments/Appointments';
import Financial from './areas/financial/Financial';

function Reports() {

  let areas = [
    { id: 0, label: "Leads" },
    { id: 1, label: "Agendamentos" },
    { id: 2, label: "Financeiro" }
  ];

  const [currentArea, setCurrentArea] = useState(areas[0].id);

  function handleAreaChange(newValue) {
    setCurrentArea(newValue);
  };

  function GetArea() {
    switch (currentArea) {
      case 0:
        return (<Leads />);
      case 1:
        return (<Appointments />);
        case 2:
          return (<Financial />);
      default:
        return (<div></div>);
    }
  }

  return (
    <div className='page-content'>
      <div className={styles.mainContainer}>
        <TabBar labels={areas.map(item => item.label)} value={currentArea} onChange={handleAreaChange} />
        <div className={styles.containerArea} >
          {GetArea()}
        </div>
      </div>
    </div>
  );
}

export default Reports;
