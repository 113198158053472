// AddWaitingListPopup.jsx
import React, { useState, useEffect } from 'react';
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import { TextField, Select, MenuItem, FormControl, InputLabel, Checkbox, FormGroup, FormControlLabel, CircularProgress, ListItemText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DataProviderInstance } from "../../api/DataProvider";
import Alert from '@mui/material/Alert';

function AddWaitingListPopup(props) {
    const { OnHide, OnCreated, initialData } = props;
    const { GetPatientById, CompanyConfig, SetWaitingList, UpdateWaitingList, GetPatients } = DataProviderInstance();

    const [selectedPatient, setSelectedPatient] = useState(null);
    const [priority, setPriority] = useState(1);
    const [status, setStatus] = useState("waiting");
    const [preferredDays, setPreferredDays] = useState([]);
    const [preferredTimes, setPreferredTimes] = useState([]);
    const [resource, setResource] = useState('');
    const [procedure, setProcedure] = useState('');
    const [observations, setObservations] = useState('');
    const [validationError, setValidationError] = useState("");

    const [patientsList, setPatientsList] = useState([]);
    const [sortedPatients, setSortedPatients] = useState([]);
    const [open, setOpen] = useState(false);
    const [loadingAPI, setLoadingAPI] = useState(false);

    const availableDaysOfWeek = CompanyConfig.AvailableDaysOfWeek || [];
    const availableTimeRange = CompanyConfig.AvailableTime || [0, 23];
    const blockedTimes = CompanyConfig.BlockedTimes || [];

    // Inicializar recursos e procedimentos
    useEffect(() => {
        if (CompanyConfig.Resources.length > 0 && !initialData) {
            setResource(CompanyConfig.Resources[0].Id);
        }
        if (CompanyConfig.Procedures.length > 0 && !initialData) {
            setProcedure(CompanyConfig.Procedures[0].Id);
        }
    }, [CompanyConfig, initialData]);

    // Carregar a lista de pacientes
    useEffect(() => {
        setLoadingAPI(true);
        GetPatients(false, (result) => {
            setPatientsList(result);
            setLoadingAPI(false);
        });
    }, []);

    // Preparar as opções para o Autocomplete
    useEffect(() => {
        if (patientsList.length > 0) {
            setSortedPatients(GetOptionsForSelect());
        } else {
            setSortedPatients([]);
        }
    }, [patientsList]);

    // Validar dias preferidos
    useEffect(() => {
        if (preferredDays.length > 0) {
            setValidationError("");
        } else {
            setValidationError("Selecione ao menos um dia.");
        }
    }, [preferredDays]);

    // Validar horários preferidos
    useEffect(() => {
        if (preferredTimes.length > 0) {
            setValidationError("");
        } else {
            setValidationError("Selecione ao menos um horário.");
        }
    }, [preferredTimes]);

    // Preencher os campos se for edição
    useEffect(() => {
        if (initialData) {
            const patient = GetPatientById(initialData.patientId);
            if (patient) {
                // Mapear o paciente para o formato esperado pelo Autocomplete
                const patientOption = sortedPatients.find(p => p.id === patient.id) || { id: patient.id, label: patient.name, status: patient.status };

                setSelectedPatient(patientOption);
                setPriority(initialData.priority);
                setPreferredDays(initialData.preferredDays ? initialData.preferredDays.split(',').map(Number) : []);
                setPreferredTimes(initialData.preferredTimes ? initialData.preferredTimes.split(',') : []);
                setResource(initialData.resourceId);
                setProcedure(initialData.procedureId);
                setStatus(initialData.status);
                setObservations(initialData.observations);
            }
        }
    }, []);

    // Função para criar as opções do Autocomplete
    function GetOptionsForSelect() {
        return patientsList
            .filter(patient => patient.status === "1")
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => ({
                id: item.id,
                label: item.name,
                status: item.status,
            }));
    }

    // Função de validação
    function Validate() {
        if (!selectedPatient) {
            setValidationError("Selecione um paciente.");
            return false;
        }

        if (preferredDays.length === 0) {
            setValidationError("Selecione ao menos um dia.");
            return false;
        }

        if (preferredTimes.length === 0) {
            setValidationError("Selecione ao menos um horário.");
            return false;
        }

        return true;
    }

    // Função para adicionar um novo paciente à lista de espera
    function handleAddClick() {
        if (!Validate()) return;

        const newEntry = {
            patientId: selectedPatient.id,
            priority: priority,
            preferredDays: preferredDays.join(','),
            preferredTimes: preferredTimes.join(','),
            resourceId: resource,
            procedureId: procedure
        };

        SetWaitingList(newEntry, (response) => {
            OnCreated(response);
            OnHide(true);
        });
    }

    // Função para editar um paciente existente na lista de espera
    function handleEditClick() {
        if (!Validate()) return;


        const updatedEntry = {
            id: initialData.id, // Supondo que `initialData` contém o ID do registro
            status: status,
            patientId: selectedPatient.id,
            priority: priority,
            preferredDays: preferredDays.join(','),
            preferredTimes: preferredTimes.join(','),
            resourceId: resource,
            procedureId: procedure,
            observations: observations
        };

        UpdateWaitingList(updatedEntry, (response) => {
            OnCreated(response);
            OnHide(true);
        });
    }

    // Função para renderizar o Autocomplete dos pacientes
    function getPatientsCombobox() {
        return (
            <Autocomplete
                autoComplete
                disabled={initialData}
                options={sortedPatients}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ flex: "1" }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={(event, newValue) => {
                    setSelectedPatient(newValue);
                    setValidationError(""); // Limpa o erro de validação
                }}
                value={selectedPatient}
                loading={loadingAPI}
                renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                        <ListItemText>{item.label}</ListItemText>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Paciente"
                        helperText={!selectedPatient ? 'Selecione um paciente' : ''}
                        error={!selectedPatient}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loadingAPI ? <CircularProgress color="inherit" size={10} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        );
    }

    // Função para renderizar os checkboxes de horários preferidos
    function getTimeCheckboxes() {
        const startHour = availableTimeRange[0];
        const endHour = availableTimeRange[1];

        const times = Array.from({ length: endHour - startHour + 1 }, (_, i) => {
            const hour = (startHour + i).toString().padStart(2, '0') + ":00";
            return hour;
        });

        const filteredTimes = times.filter((time) => !blockedTimes.includes(time));

        return (
            <FormGroup row>
                {filteredTimes.map((time) => (
                    <FormControlLabel
                        key={time}
                        control={
                            <Checkbox
                                checked={preferredTimes.includes(time)}
                                onChange={() => toggleTime(time)}
                            />
                        }
                        label={time}
                    />
                ))}
            </FormGroup>
        );
    }

    // Função para renderizar o conteúdo do popup
    function GetContent() {
        return (
            <div style={{ marginTop: "10px" }}>
                <div className="form">
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                        <FormControl fullWidth style={{ marginBottom: "15px" }}>
                            {getPatientsCombobox()}
                        </FormControl>

                        <FormControl fullWidth >
                            <InputLabel>Status</InputLabel>
                            <Select disabled={!initialData} value={status} onChange={(e) => setStatus(e.target.value)}>
                                <MenuItem value="waiting">Waiting</MenuItem>
                                <MenuItem value="scheduled">Scheduled</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth >
                            <InputLabel>Prioridade</InputLabel>
                            <Select value={priority} onChange={(e) => setPriority(parseInt(e.target.value))}>
                                <MenuItem value={1}>Alta</MenuItem>
                                <MenuItem value={2}>Média</MenuItem>
                                <MenuItem value={3}>Baixa</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                        <FormControl fullWidth >
                            <InputLabel>Profissional</InputLabel>
                            <Select value={resource} onChange={(e) => setResource(e.target.value)}>
                                {CompanyConfig.Resources.map((res) => (
                                    <MenuItem key={res.Id} value={res.Id}>{res.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel>Procedimento</InputLabel>
                            <Select value={procedure} onChange={(e) => setProcedure(e.target.value)}>
                                {CompanyConfig.Procedures.map((proc) => (
                                    <MenuItem key={proc.Id} value={proc.Id}>{proc.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>               

                    <div style={{display:"flex",flexDirection:"column" ,padding:"30px", gap:"10px", backgroundColor:"#f5f5f5", borderRadius:"10px", marginBottom:"10px", marginRight:"10px"}}>
                        <h4>Dias Preferidos</h4>
                        <FormGroup row>
                            {availableDaysOfWeek.includes(0) && (
                                <FormControlLabel
                                    control={<Checkbox checked={preferredDays.includes(0)} onChange={() => toggleDay(0)} />}
                                    label="Domingo"
                                />
                            )}
                            {availableDaysOfWeek.includes(1) && (
                                <FormControlLabel
                                    control={<Checkbox checked={preferredDays.includes(1)} onChange={() => toggleDay(1)} />}
                                    label="Segunda"
                                />
                            )}
                            {availableDaysOfWeek.includes(2) && (
                                <FormControlLabel
                                    control={<Checkbox checked={preferredDays.includes(2)} onChange={() => toggleDay(2)} />}
                                    label="Terça"
                                />
                            )}
                            {availableDaysOfWeek.includes(3) && (
                                <FormControlLabel
                                    control={<Checkbox checked={preferredDays.includes(3)} onChange={() => toggleDay(3)} />}
                                    label="Quarta"
                                />
                            )}
                            {availableDaysOfWeek.includes(4) && (
                                <FormControlLabel
                                    control={<Checkbox checked={preferredDays.includes(4)} onChange={() => toggleDay(4)} />}
                                    label="Quinta"
                                />
                            )}
                            {availableDaysOfWeek.includes(5) && (
                                <FormControlLabel
                                    control={<Checkbox checked={preferredDays.includes(5)} onChange={() => toggleDay(5)} />}
                                    label="Sexta"
                                />
                            )}
                            {availableDaysOfWeek.includes(6) && (
                                <FormControlLabel
                                    control={<Checkbox checked={preferredDays.includes(6)} onChange={() => toggleDay(6)} />}
                                    label="Sábado"
                                />
                            )}
                        </FormGroup>
                    </div>

                    <div style={{display:"flex",flexDirection:"column" ,padding:"30px", gap:"10px", backgroundColor:"#f5f5f5", borderRadius:"10px", marginBottom:"10px", marginRight:"10px"}}>
                        <h4>Horários Preferidos</h4>
                        {getTimeCheckboxes()}
                    </div>

                    <div>
                        <FormControl fullWidth>
                            <TextField
                                label="Observações"
                                multiline
                                rows={3}
                                value={observations}
                                onChange={(e) => setObservations(e.target.value)}
                                placeholder="Digite observações sobre..."
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    
                </div>
            </div>
        );
    }

    // Função para determinar o título do popup
    function GetTitle() {
        return initialData ? "Editar" : "Adicionar à Lista de Espera";
    }

    // Função para determinar o footer do popup
    function GetFooter() {
        return (
            <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                {validationError !== "" && <Alert severity="error">{validationError}</Alert>}
                <CustomButton variant="outlined" style="secondary" label={"Cancelar"} onClick={() => OnHide(false)} />
                {initialData ? (
                    <CustomButton variant="contained" style="primary" label={"Salvar alterações"} onClick={handleEditClick} />
                ) : (
                    <CustomButton variant="contained" style="primary" label={"Adicionar"} onClick={handleAddClick} />
                )}
            </div>
        );
    }

    // Função para alternar dias preferidos
    function toggleDay(day) {
        if (preferredDays.includes(day)) {
            setPreferredDays(preferredDays.filter(d => d !== day));
        } else {
            setPreferredDays([...preferredDays, day]);
        }
    }

    // Função para alternar horários preferidos
    function toggleTime(time) {
        if (preferredTimes.includes(time)) {
            setPreferredTimes(preferredTimes.filter(t => t !== time));
        } else {
            setPreferredTimes([...preferredTimes, time]);
        }
    }

    return (
        <PopupBase Title={GetTitle()} Content={GetContent()} Footer={GetFooter()} OnClose={() => OnHide(false)} />
    );
}

export default AddWaitingListPopup;
